// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Container, Row, Collapse, Col, Card, CardBody, CardHeader,
} from "reactstrap";
import { getUser } from '../service/FetchUtil';
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
// Import images
import sliderimg from "../assets/images/bulut12/sliderr/efatura-min.jpg";
import image from "../assets/images/bulut12/e-fatura/efaturaanagorseli2.png";
import tik from "../assets/images/bulut12/tik.png";
import appstore from "../assets/images/bulut12/appstore.png";
import playstore from "../assets/images/bulut12/playstore.png";
import efatura from "../assets/images/bulut12/e-fatura/fatura.png";
import soru from "../assets/images/bulut12/e-fatura/soru.png";
import satisfaturasi from "../assets/images/bulut12/satisfaturasilaptop3.png";
import dovizlifatura from "../assets/images/bulut12/dovizlifatura.png";
import responsive from "../assets/images/bulut12/responsiveekranlar.png";
import gib from "../assets/images/bulut12/gib.png"

//Import Cloud Images
import cloud_1 from "../assets/images/cloud_01.png";
import cloud_2 from "../assets/images/cloud_02.png";
import cloud_3 from "../assets/images/cloud_03.png";
import cloud_4 from "../assets/images/cloud_04.png";
//Import Slick Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/style.css";

class eFaturaSoru extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pricings: [
                {
                    features: [
                        {
                            id: 1,
                            image: tik,
                            title: "Gereksiz Masraflardan Kurtulun",
                            p: "Herhangi bir ek maliyet ödemesi yapmadan e-Dönüşüm platformuna geçin, baskı, kargolama ve arşivleme gibi işlerden kurtulun."
                        },
                        {
                            id: 2,
                            image: tik,
                            title: "Müşterilere Hızlı Fatura İletin",
                            p: "Kesilen faturalar elektronik posta yolu ile tek tıkla müşterilerinize vakit kaybetmeden ulaştırın ki, tahsilat süreciniz hemen başlasın."
                        },
                        {
                            id: 3,
                            image: tik,
                            title: "Faturalarınız Kaybolmasın",
                            p: "Ücretsiz olarak bulut sisteminde yedeklenen ticari belgeleriniz kaybolmaz. Dilediğiniz zaman faturalarınıza tek tıkla ulaşırsınız."
                        },
                        {
                            id: 4,
                            image: tik,
                            title: "Zamanınızı İyi Kullanın",
                            p: "Dijital ortamda arşivlenen faturalarınıza kolayca erişim sağlayın, fatura ararken kaybettiğiniz zamanı kendinize ayırın."
                        },

                        {
                            id: 5,
                            image: tik,
                            title: "Çevre Dostu Olun",
                            p: "Kağıt fatura kesmek yerine e-Fatura'ya kolayca geçiş sağlayın, ağaç kesiminin önüne geçin, geleceğinizi koruma altına alın."
                        },
                    ],
                    features2: [
                        {
                            id: 6,
                            image: tik,
                            title: "Kullanıcı Odaklı Arayüz Tasarımı",
                            p: "e-Fatura takip sürecine vakit harcamayın, faturalarınızı hemen ulaştırın."
                        },
                        // {
                        //     id: 7,
                        //     image: tik,
                        //     title: "Kolay Kullanım İmkanı",
                        //     p: "e-Fatura takip sürecine vakit harcamayın, faturalarınızı hemen ulaştırın.Tahsilat sürecinizi."
                        // },
                        {
                            id: 8,
                            image: tik,
                            title: "Her Yerden Erişim Özelliği",
                            p: "e-Fatura takip sürecine vakit harcamayın, faturalarınızı hemen ulaştırın.Tahsilat sürecinizi."
                        },
                        {
                            id: 9,
                            image: tik,
                            title: "Üstün Güvenlikli Hizmet Anlayışı",
                            p: "e-Fatura takip sürecine vakit harcamayın, faturalarınızı hemen ulaştırın.Tahsilat sürecinizi."
                        },
                        {
                            id: 10,
                            image: tik,
                            title: "Tecrübeli Ekip Desteği",
                            p: "e-Fatura takip sürecine vakit harcamayın, faturalarınızı hemen ulaştırın.Tahsilat sürecinizi."
                        },
                        {
                            id: 11,
                            image: tik,
                            title: "Bir Arada Modüler Çözümler",
                            p: "e-Fatura takip sürecine vakit harcamayın, faturalarınızı hemen ulaştırın.Tahsilat sürecinizi."
                        },
                        {
                            id: 12,
                            image: tik,
                            title: "Kurulum Gerektirmeyen Hizmet",
                            p: "e-Fatura takip sürecine vakit harcamayın, faturalarınızı hemen ulaştırın.Tahsilat sürecinizi."
                        },
                        {
                            id: 12,
                            image: tik,
                            title: "Tüm Ekranlarda Kullanabilme İmkanı",
                            p: "e-Fatura takip sürecine vakit harcamayın, faturalarınızı hemen ulaştırın.Tahsilat sürecinizi."
                        },
                        {
                            id: 13,
                            image: tik,
                            title: "Hızlı, Kolay ve Pratik İşlemler",
                            p: "e-Fatura takip sürecine vakit harcamayın, faturalarınızı hemen ulaştırın.Tahsilat sürecinizi."
                        },
                        {
                            id: 14,
                            image: tik,
                            title: "Ücretsiz Deneme Özelliği",
                            p: "e-Fatura takip sürecine vakit harcamayın, faturalarınızı hemen ulaştırın.Tahsilat sürecinizi."
                        },
                    ],
                },
            ],
            isOpen: false,
            collapse1: false,
            col1: false,
            col2: false,
            col3: false,
            col4: false,
            col5: false,
            col6: false,
        };
        this.openModal = this.openModal.bind(this);
        this.t_col1 = this.t_col1.bind(this);
        this.t_col2 = this.t_col2.bind(this);
        this.t_col3 = this.t_col3.bind(this);
        this.t_col4 = this.t_col4.bind(this);
        this.t_col5 = this.t_col5.bind(this);
        this.t_col6 = this.t_col6.bind(this);
        this.t_col7 = this.t_col7.bind(this);
        this.t_col8 = this.t_col8.bind(this);
        this.t_col9 = this.t_col9.bind(this);

        this.states = {
            successMsg: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({ successMsg: true });
        }

        openModal() {
            this.setState({ isOpen: true });    
    }
    t_col1() {
        this.setState({
            col1: !this.state.col1,
            col2: false,
            col3: false,
            col4: false,
            col5: false,
            col6: false,
            col7: false,
            col8: false,
            col9: false,
        });
    }
    t_col2() {
        this.setState({
            col2: !this.state.col2,
            col1: false,
            col3: false,
            col4: false,
            col5: false,
            col6: false,
            col7: false,
            col8: false,
            col9: false,
        });
    }
    t_col3() {
        this.setState({
            col3: !this.state.col3,
            col2: false,
            col1: false,
            col4: false,
            col5: false,
            col6: false,
            col7: false,
            col8: false,
            col9: false,
        });
    }
    t_col4() {
        this.setState({
            col4: !this.state.col4,
            col2: false,
            col3: false,
            col1: false,
            col5: false,
            col6: false,
            col7: false,
            col8: false,
            col9: false,
        });
    }
    t_col5() {
        this.setState({
            col5: !this.state.col5,
            col2: false,
            col3: false,
            col1: false,
            col4: false,
            col6: false,
            col7: false,
            col8: false,
            col9: false,
        });
    }

    t_col6() {
        this.setState({
            col6: !this.state.col6,
            col2: false,
            col3: false,
            col1: false,
            col4: false,
            col5: false,
            col7: false,
            col8: false,
            col9: false,
        });
    }

    t_col7() {
        this.setState({
            col7: !this.state.col7,
            col2: false,
            col3: false,
            col1: false,
            col4: false,
            col5: false,
            col6: false,
            col8: false,
            col9: false,
        });
    }

    t_col8() {
        this.setState({
            col8: !this.state.col8,
            col2: false,
            col3: false,
            col1: false,
            col4: false,
            col5: false,
            col6: false,
            col7: false,
            col9: false,
        });
    }

    t_col9() {
        this.setState({
            col9: !this.state.col9,
            col2: false,
            col3: false,
            col1: false,
            col4: false,
            col5: false,
            col6: false,
            col7: false,
            col8: false,
        });
    }
    componentDidMount() {
        var e1 = document.getElementsByClassName("slick-slide");
        e1[0].style.backgroundImage = `url(${sliderimg})`;
    }
    render() {
        const user = getUser();
        return (
            <React.Fragment>
                <section className="main-slider erp_banner_area_two">
                    <Slider className="slides" >
                        <li className={"bg-slider d-flex align-items-center slider-rtl-2"} >
                            <Container>
                                <ul class="list-unstyled cloud_animation">
                                    <li><img src={cloud_1} alt="" /></li>
                                    <li><img src={cloud_2} alt="" /></li>
                                    <li><img src={cloud_3} alt="" /></li>
                                    <li><img src={cloud_4} alt="" /></li>
                                </ul>
                                <Row className="slider-top align-items-center col-lg-12 col-md-6">
                                    <Col className="slider-desc">
                                        <div className="title-heading">
                                            <p className="pera-slider1 pera-h1 title-white font-weight-bold">
                                                e-Fatura geçişlerinde merak edilenler
                                            </p>
                                            <p className="pera-slider2 font-weight-200 para-dark">
                                                Tek tıkla internetinizin olduğu her yerden, akıllı telefonunuzdan, tabletinizden ya da bilgisayarınızdan müşterilerinize
                                                elektronik faturalarınızı anında ulaştırın.
                                            </p>
                                            {/* <div className="mt-4 slider-btn">
                                                {user ?
                                                    (!user.data ? <Link to="register"
                                                        testid="btnregister"
                                                        className="btn  btn-stildetay btn-primary ml-10  font-weight-500 mb-3  rounded-pill"
                                                    //  onClick={this.toggleLoginModal}
                                                    >
                                                        7 Gün Ücretsiz Deneyin
                                                    </Link> : null) :
                                                    <Link to="#"
                                                        testid="btnregister"
                                                        className="btn  btn-stildetay btn-primary ml-10  font-weight-500 mb-3  rounded-pill"
                                                        onClick={this.toggleLoginModal}
                                                    >
                                                        7 Gün Ücretsiz Deneyin
                                                    </Link>
                                                }
                                            </div> */}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </li>
                    </Slider>
                </section>
                <section className="gecis-section" 
                style={{
                    marginTop:-35
                }}
                >
                    <div className="col-12 text-left">
                        <Container className="my_section" id="faturanedir">
                            <Col lg={12}>
                                <Row className="position-relative align-items-center nedir-row">
                                    <Col className="eftr-area" xl={9} lg={8} md={8} sm={12} xs={12}>
                                        <div className="features text-left align-items-center">
                                            <h3 className="peramini-b" >e-Fatura Nedir?</h3>
                                            <text className="eftrtext">
                                                e-Fatura dijital ortamda oluşturulan elektronik bir belgedir. İçeriğinde
                                                ise Vergi Usul Kanunu'na göre belirlenmiş bilgiler mevcuttur. Kağıt
                                                fatura gibi elde hazırlamak ya da yazdırmak yerine dijital ortamda
                                                oluşturulur ve gönderilir. Her iki fatura çeşidide yasal olmakla beraber
                                                e-Fatura'nın tek farkı dijital ortam üzerinden alınması ve
                                                gönderilmesidir. Ayrıca bazı şartlara sahip mükelleflere ise e-Fatura
                                                kullanım zorunluluğuda getirilmiştir.
                                            </text>
                                        </div>
                                    </Col>
                                    <Col className="eftr-button" xl={2} lg={4} md={4} sm={12} xs={12}>
                                        <a href="https://apps.apple.com/app/bulut12/id1594430401" target="_blank" rel="noopener noreferrer">
                                            <div >
                                                <img src={appstore} className="eftr-img" alt="" />
                                            </div>
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.yazilim12.bulut12.mobile" target="_blank" >
                                            <div className="mt-2" >
                                                <img src={playstore} className="eftr-img" alt="" />
                                            </div>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Container>
                    </div>
                </section>
                
                <section className="section bg p-4 justify-content-center" id="sorular">
                    <Container className="col-xl-8 border rounded mt-50 mb-50" style={{ background: 'white', marginTop: '50px', marginBottom: '50px' }}>
                        <Row className="align-items-center mt-50 mb-50" style={{ background: 'white', marginTop: '50px', marginBottom: '50px' }}>
                            <Col xl="7" lg="7" md="12" className="mt-8 mt-sm-0 pt-sm-0">
                                <div className="col-12 text-center mb-4">
                                    <h3 className="h3test ">
                                        SIKÇA SORULAN SORULAR
                                    </h3>

                                </div>
                                <div className="faq-content me-lg-0">
                                    <div className="accordion" id="accordionExample">
                                    <Card className="border-0 rounded mb-2" style={{ cursor: 'pointer' }}>
                                            <CardHeader
                                                className="border-0 bg-light p-2 pe-2"
                                                id="headingfive"
                                                onClick={this.t_col5}
                                            >
                                                <h5 className="title mb-0" style={{ color: '#f77e66' }}>
                                                    Bulut12 e-fatura geçişinde ne yapmalıyım?
                                                    <i
                                                        className={
                                                            this.state.col5
                                                                ? "mdi mdi-chevron-up float-right"
                                                                : "mdi mdi-chevron-down float-right"
                                                        }
                                                    ></i>
                                                </h5>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.col5}>
                                                <CardBody>
                                                    <p className="text-muted mb-0 faq-ans">
                                                        Bulut12 ön muhasebe programına geçiş yapmadan önce çalıştığınız firma ile iletişime geçerek önceki posta kodunuzun
                                                        değiştiğini ve gelen e-fatura işlemlerine kapatılmasını istemeniz gerekmektedir. 
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                        <Card className="border-0 rounded mb-2" style={{ cursor: 'pointer' }}>
                                            <CardHeader
                                                className="border-0 bg-light p-2 pe-2"
                                                id="headingOne"
                                                onClick={this.t_col1}
                                            >
                                                <h5 className="title mb-0" style={{ color: '#f77e66' }}>
                                                    e-Fatura ücretsiz mi?
                                                    <i
                                                        className={

                                                            this.state.col1
                                                                ? "mdi mdi-chevron-up float-right"
                                                                : "mdi mdi-chevron-down float-right"
                                                        }
                                                    ></i>
                                                </h5>

                                            </CardHeader>
                                            <Collapse isOpen={this.state.col1}>
                                                <CardBody>
                                                    <p className="text-muted mb-0 faq-ans">
                                                        Bulut12'de e-fatura, e-arşiv, e-smm, e-müstahsil, e-irsaliye için entegrasyon ücretsizdir. Yeni üyeliklerde paket içeriğinde hediye edilen belirli miktarda e-kontör bulunmaktadır.
                                                        Hediye edilen e-kontör bittiğinde ise kontör yüklemesi için Bulut12 panelinden kontör satın alma işleminin yapılması gerekmektedir.
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>

                                        <Card className="border-0 rounded mb-2" style={{ cursor: 'pointer' }}>
                                            <CardHeader
                                                className="border-0 bg-light p-2 pe-2"
                                                id="headingTwo"
                                                onClick={this.t_col2}
                                            >
                                                <h5 className="title mb-0" style={{ color: '#f77e66' }}>
                                                    e-Fatura kimler için zorunludur?
                                                    <i
                                                        className={
                                                            this.state.col2
                                                                ? "mdi mdi-chevron-up float-right"
                                                                : "mdi mdi-chevron-down float-right"
                                                        }
                                                    ></i>
                                                </h5>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.col2}>
                                                <CardBody>
                                                    <p className="text-muted mb-0 faq-ans">
                                                        Yayımlanan Vergi Usulü Kanununa göre 2020 ve 2021 hesap dönemleri brüt satış hasılatı 1 milyon TL olan, 
                                                        2022 ve sonrasında ki yıllar için ise 500 bin TL ve üzeri satış hasılatı olan satıcılara e-Fatura kullanımı zorunlu hale getirilmiştir.
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>

                                        <Card className="border-0 rounded mb-2" style={{ cursor: 'pointer' }}>
                                            <CardHeader
                                                className="border-0 bg-light p-2 pe-2"
                                                id="headingfive"
                                                onClick={this.t_col3}
                                            >
                                                <h5 className="title mb-0" style={{ color: '#f77e66' }}>
                                                    e-Fatura geçiş şartları nelerdir?
                                                    <i
                                                        className={
                                                            this.state.col3
                                                                ? "mdi mdi-chevron-up float-right"
                                                                : "mdi mdi-chevron-down float-right"
                                                        }
                                                    ></i>
                                                </h5>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.col3}>
                                                <CardBody>
                                                    <p className="text-muted mb-0 faq-ans">
                                                        GİB tarafından yıllık cirosu 4 milyon TL'yi aşan firmaların e-Fatura zorunluluğu olsa da cirosu bu tutarı geçmeyen
                                                        firmalarda e-Fatura uygulamasından faydalanabilirler.
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>

                                        <Card className="border-0 rounded mb-2" style={{ cursor: 'pointer' }}>
                                            <CardHeader
                                                className="border-0 bg-light p-2 pe-2"
                                                id="headingfive"
                                                onClick={this.t_col4}
                                            >
                                                <h5 className="title mb-0" style={{ color: '#f77e66' }}>
                                                    Herkese e-Fatura kesebilir miyim, e-Fatura nasıl kesilir?
                                                    <i
                                                        className={
                                                            this.state.col4
                                                                ? "mdi mdi-chevron-up float-right"
                                                                : "mdi mdi-chevron-down float-right"
                                                        }
                                                    ></i>
                                                </h5>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.col4}>
                                                <CardBody>
                                                    <p className="text-muted mb-0 faq-ans">
                                                        e-Fatura yanlızca e-Fatura uygulamasını kullanan mükellefler arasında kesilebilmektedir. Fakat e-Fatura kullanıcısı iseniz
                                                        kağıt fatura kullanmaya devam eden müşterilerinize e-Arşiv kesebilirsiniz.
                                                        Yani e-Fatura herkese kesilemez. e-Fatura aktivasyonunuz tamamlandıktan sonra keseceğiniz faturayı Bulut12 üzerinden
                                                        müşterilerinize kolayca gönderebilirsiniz. Fatura oluştururken kaydettiğiniz vergi numarasına göre e-Fatura ya da <a href="/earsiv">e-Arşiv</a> fatura olarak otomatik gönderim sağlarsınız.
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>

                                        <Card className="border-0 rounded mb-2" style={{ cursor: 'pointer' }}>
                                            <CardHeader
                                                className="border-0 bg-light p-2 pe-2"
                                                id="headingfive"
                                                onClick={this.t_col5}
                                            >
                                                <h5 className="title mb-0" style={{ color: '#f77e66' }}>
                                                    e-Fatura başvurusu için neler gereklidir, nasıl başvuru yapılır?
                                                    <i
                                                        className={
                                                            this.state.col5
                                                                ? "mdi mdi-chevron-up float-right"
                                                                : "mdi mdi-chevron-down float-right"
                                                        }
                                                    ></i>
                                                </h5>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.col5}>
                                                <CardBody>
                                                    <p className="text-muted mb-0 faq-ans">
                                                        GİB üzerinden e-Fatura'ya geçiş için başvuru yapılır. Onaylandıktan sonra mali mühür ödeme işlemi ve GİB üzerinden özel entegratör başvurusu gerçekleştirilir.
                                                        Tamamlanan onaylar sonrası işletmenin kullanımına yönelik e-Fatura hesabı açılır.
                                                        Mali mühür başvurusu için: <a href="https://mportal.kamusm.gov.tr/bp/edf.go">https://mportal.kamusm.gov.tr/bp/edf.go</a><br />
                                                        e-Fatura başvurusu için: <a href="https://portal.efatura.gov.tr/efaturabasvuru/">https://portal.efatura.gov.tr/efaturabasvuru/</a>
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>


                                        <Card className="border-0 rounded mb-2" style={{ cursor: 'pointer' }}>
                                            <CardHeader
                                                className="border-0 bg-light p-2 pe-2"
                                                id="headingfive"
                                                onClick={this.t_col6}
                                            >
                                                <h5 className="title mb-0" style={{ color: '#f77e66' }}>
                                                    Yıl içerisinde e-Fatura'ya geçebilir miyim?
                                                    <i
                                                        className={
                                                            this.state.col6
                                                                ? "mdi mdi-chevron-up float-right"
                                                                : "mdi mdi-chevron-down float-right"
                                                        }
                                                    ></i>
                                                </h5>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.col6}>
                                                <CardBody>
                                                    <p className="text-muted mb-0 faq-ans">
                                                        İstek üzerine e-Fatura'ya geçmek isteyen işletmeler yılın herhangi bir günün de geçiş sağlayabilir.
                                                        Fakat e-Fatura'ya geçiş zorunluluğu olanlar ise yeni yılın başında geçiş işlemlerini gerçekleştirebilirler.
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>



                                        <Card className="border-0 rounded mb-2" style={{ cursor: 'pointer' }}>
                                            <CardHeader
                                                className="border-0 bg-light p-2 pe-2"
                                                id="headingfive"
                                                onClick={this.t_col7}
                                            >
                                                <h5 className="title mb-0" style={{ color: '#f77e66' }}>
                                                    Geriye dönük kaç gün için fatura düzenlenebilir?
                                                    <i
                                                        className={
                                                            this.state.col7
                                                                ? "mdi mdi-chevron-up float-right"
                                                                : "mdi mdi-chevron-down float-right"
                                                        }
                                                    ></i>
                                                </h5>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.col7}>
                                                <CardBody>
                                                    <p className="text-muted mb-0 faq-ans">
                                                        Vergi Usul Kanunu'na göre, arz edilen ürünün veya hizmetin faturasının en geç 7 gün içinde oluşturulması gerekmektedir.
                                                        7 günü geçen ürünün-hizmetin faturası kesilmemiş ise geriye dönük olarak fatura kesilemez.
                                                        En fazla geriye dönük 7 gün içerisinde fatura düzenlenir.
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>


                                        <Card className="border-0 rounded mb-2" style={{ cursor: 'pointer' }}>
                                            <CardHeader
                                                className="border-0 bg-light p-2 pe-2"
                                                id="headingfive"
                                                onClick={this.t_col8}
                                            >
                                                <h5 className="title mb-0" style={{ color: '#f77e66' }}>
                                                    e-Fatura kullanıcısı kağıt fatura kesebilir mi?
                                                    <i
                                                        className={
                                                            this.state.col8
                                                                ? "mdi mdi-chevron-up float-right"
                                                                : "mdi mdi-chevron-down float-right"
                                                        }
                                                    ></i>
                                                </h5>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.col8}>
                                                <CardBody>
                                                    <p className="text-muted mb-0 faq-ans">
                                                        e-Fatura mükellefi olan bir işletme, müşterisi de e-Fatura kullanıcısı ise kağıt fatura kesemez, gönderemez.
                                                        Fakat müşterisi e-Fatura kullanıcısı değil ise kağıt fatura ya da e-Arşiv fatura düzenler. e-Arşiv faturayıda müşterisiyle e-mail yolu ile paylaşabilir.
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>


                                        <Card className="border-0 rounded mb-2" style={{ cursor: 'pointer' }}>
                                            <CardHeader
                                                className="border-0 bg-light p-2 pe-2"
                                                id="headingfive"
                                                onClick={this.t_col9}
                                            >
                                                <h5 className="title mb-0" style={{ color: '#f77e66' }}>
                                                    e-Fatura sistemi ile irsaliye hazırlayabilir miyim?
                                                    <i
                                                        className={
                                                            this.state.col9
                                                                ? "mdi mdi-chevron-up float-right"
                                                                : "mdi mdi-chevron-down float-right"
                                                        }
                                                    ></i>
                                                </h5>
                                            </CardHeader>
                                            <Collapse isOpen={this.state.col9}>
                                                <CardBody>
                                                    <p className="text-muted mb-0 faq-ans">
                                                        e-Fatura oluştururken belgedeki düzenleme tarihinin yanına düzenlenme saati ve dakikası yazılıp çıktısı alınırsa irsaliye fatura yerine de kullanılabilir.
                                                        Ayrıca belgenin üzerine "irsaliye yerine geçer" ibaresi de eklenebilir.
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="5" lg="5" md="12" className="mt-8 mt-sm-0 pt-5 pt-sm-0 sss-col">
                                <Card style={{ backgroundColor: '#e4f2f9', border: 'none' }}>
                                    <CardBody>
                                        <img className="sss-img" src={soru} alt="" />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section p-5 erp_banner_area_two" id="git">
                    <Container className="justify-content-center eticaretcont">
                    <ul class="list-unstyled cloud_animation">
                    <li><img src={cloud_1} alt="" /></li>
                    <li><img src={cloud_2} alt="" /></li>
                    <li><img src={cloud_3} alt="" /></li>
                    <li><img src={cloud_4} alt="" /></li>
                  </ul>
                        <Row className="justify-content-center avantajlarrow" >
                            <Col xl="7"
                                lg="6"
                                md="12"
                                sm="12"
                                xs="12">
                                {this.state.pricings.map((pricing, key) => (
                                    <Col
                                        name="pricing"
                                        key={key}
                                        className="mt-4 pt-2 d-flex align-items-center"
                                    >
                                        <Card
                                            name="pricingbox"
                                            style={{ borderRadius: '10px', backgroundColor: '#ffffff', border: 'none'}}
                                            className="d-flex align-items-center avantajlar-blok"
                                        >
                                            <CardBody className="avantajlar-col" >
                                                <h2
                                                    style={{ color: "#f77e66", position: 'relative', fontWeight: 'bold', marginBottom: "40px" }}
                                                    className="d-flex align-items-center"
                                                >
                                                    e-Fatura'nın Avantajları
                                                </h2>
                                                <ul className="list-unstyled mb-0 pl-0">
                                                    {pricing.features.map((feature, key) => (
                                                        <li key={key} className="h5 mb-0">
                                                            <span className="align-middle h5 mr-2">
                                                                <i className="uil uil-check-circle text-primary align-middle"></i>
                                                            </span>
                                                            {feature.title}<br /><p className="eftrtext text-muted" style={{ fontWeight: 'lighter' }}>{feature.p}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Col>
                            <Col xl="5"
                                lg="6"
                                md="12"
                                sm="12"
                                xs="12">
                                {this.state.pricings.map((pricing, key) => (
                                    <Col
                                        name="pricing"
                                        key={key}
                                        className="mt-4 pt-2 d-flex justify-content-center"

                                    >
                                        <Card
                                            name="pricingbox"
                                            style={{ borderRadius: '10px', backgroundColor: '#ffffff', border: 'none', width: '100%' }}
                                            className="d-flex justify-content-center"

                                        >
                                            <CardBody >
                                                <div className=" d-flex justify-content-center text-center">

                                                    <small>
                                                        <img src={efatura} />
                                                    </small>

                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                                {this.state.pricings.map((pricing, key) => (
                                    <Col
                                        name="pricing"
                                        key={key}
                                        className="d-flex align-items-center avantajlar-blok3"

                                    >
                                        <Card
                                            name="pricingbox"
                                            style={{ borderRadius: '10px', backgroundColor: '#ffffff', border: 'none', width: '100%' }}
                                            className="d-flex align-items-center"

                                        >
                                            <CardBody className="avantajlar-col">
                                                <h3
                                                    style={{ color: "#f77e66", position: 'relative', fontWeight: 'bold' }}
                                                    className="d-flex align-items-center"
                                                >
                                                    Neden Bulut12?
                                                </h3>
                                                <ul className="list-unstyled mb-0 pl-0">
                                                    {pricing.features2.map((feature, key) => (
                                                        <li key={key} className="h5 mb-0">
                                                            <span className="align-middle h5 mr-2">
                                                                <img
                                                                    src={pricing.image}
                                                                    className="ml-0"
                                                                    height="25"
                                                                    alt=""
                                                                />
                                                                <i className="uil uil-check-circle text-primary align-middle"></i>
                                                            </span>
                                                            {feature.title}<br /><p className="text-muted" style={{ fontWeight: 'lighter', marginLeft: '30px' }}></p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section bg-soft-warning">
                    <Container className="eticaretcont">
                        <Col lg="12">
                            <Row className="align-items-center" >
                                <Col lg="5" md="5">
                                    <h2 className="peramini-b">Tüm Ekranlarda</h2>
                                    <h4 style={{color:"#474d4f", lineHeight:"normal"}} className="peramini-b mb-4">Bulut12 Ön Muhasebe Programı'na Farklı Cihazlardan Erişim Sağlayın</h4>
                                         <h5 className="uil uil-check-circle text-primary align-middle">Web Tarayıcılarda</h5>
                                         <h5 className="uil uil-check-circle text-primary align-middle">Tabletlerde</h5>
                                         <h5 className="uil uil-check-circle text-primary align-middle">Akıllı Telefonlarda</h5>
                                </Col>
                                <Col lg="7" md="7">
                                    <img className="responsiveapp" src={responsive} alt="" />
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(eFaturaSoru));