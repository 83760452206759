import Swal from 'sweetalert2';

const ApiOptions = {
    credentials: 'same-origin',
    contentType: 'application/json',
    mode: 'cors',
    userKey: 'bl12-user',
    cartKey: 'bl12-cart'
};

/*USERADDRESS_UPDATE: "USERADDRESS_UPDATE"*/
export const apiRights = {
    CAMPAIGN_NEW: "CAMPAIGN_NEW",
    CAMPAIGN_UPDATE: "CAMPAIGN_UPDATE",
    CIRO_SHOW: "CIRO_SHOW",
    CAMPAIGN_DELETE: "CAMPAIGN_DELETE",
    POD_NEW: "POD_NEW",
    POD_UPDATE: "POD_UPDATE",
    POD_STARTSTOP: "POD_STARTSTOP",
    POD_DELETE: "POD_DELETE",
    TENANT_UPDATE: "TENANT_UPDATE",
    TENANT_STARTSTOP: "TENANT_STARTSTOP",
    TENANT_DELETE: "TENANT_DELETE",
    CATEGORY_NEW: "CATEGORY_NEW",
    CATEGORY_UPDATE: "CATEGORY_UPDATE",
    CATEGORY_DELETE: "CATEGORY_DELETE",
    EDOCUMENTCREDITS_SHOW: "EDOCUMENTCREDITS_SHOW",
    USER_UPDATE: "USER_UPDATE",
    RESELLER_UPDATE: "RESELLER_UPDATE",
    RESELLER_STARTSTOP: "RESELLER_STARTSTOP",
    RESELLER_DELETE: "RESELLER_DELETE",
    RESELLER_NEW: "RESELLER_NEW",
    TEMPLATE_NEW: "TEMPLATE_NEW",
    TEMPLATE_UPDATE: "TEMPLATE_UPDATE",
    TEMPLATE_DELETE: "TEMPLATE_DELETE",
    TENANTINVOICE_UPDATE: "TENANTINVOICE_UPDATE",
    USERADDRESS_UPDATE: "USERADDRESS_UPDATE"
}

export const setupApiOptions = (options) => {
    if (!options)
        return;
    if (options.credentials)
        ApiOptions.credentials = options.credentials;
    if (options.contentType)
        ApiOptions.contentType = options.contentType;
    if (options.baseUrl)
        ApiOptions.baseUrl = options.baseUrl;
}

export const callApi = (url, options, hsuccess, herror, oncomplete) => {
    const headers = {
        Accept: 'application/json'
    }
    if (options.data || options.rawData) {
        if (!options.method)
            options.method = 'POST';
        if (options.data) {
            options.body = JSON.stringify(options.data);
            delete options.data;
            headers['Content-Type'] = options.contentType || ApiOptions.contentType;
        } else {
            options.body = options.rawData;
            delete options.rawData;
        }
    }
    var code = null;
    if (!url.startsWith('http')) {
        if (!ApiOptions.baseUrl) {
            herror({ message: "No defined host found." });
            return;
        } else
            url = ApiOptions.baseUrl + url;
    }
    fetch(url, {
        credentials: ApiOptions.credentials,//'same-origin'
        mode: ApiOptions.mode,
        cache: "no-cache",
        headers,
        ...options
    }).then(response => {
        code = response.status;
        if (response.ok) {
            const contentType = response.headers.get('Content-Type') || '';
            if (contentType.includes('application/json')) {
                response.json().then(obj => {
                    if (hsuccess)
                        hsuccess(obj);
                }, error => {
                    console.error(response.text);
                    if (options._ignoreResponse_) {
                        hsuccess();
                    } else {
                        const r = { title: 'Çözümlenemeyen veri', message: error.message, code };
                        handleError(r, herror);
                    }
                });
            } else if (contentType.includes('text/html')) {
                response.text().then(html => {
                    hsuccess({
                        page_type: 'generic',
                        html: html
                    });
                }, error => {
                    const r = { title: 'Çözümlenemeyen html', message: error.message, code };
                    handleError(r, herror);
                });
            } else if (code != 204) {
                response.blob().then(blob => {
                    const disposition = response.headers.get('Content-Disposition') || '';
                    var name = '';
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            name = matches[1].replace(/['"]/g, '');
                        }
                    }
                    hsuccess({ blob, name, contentType });
                }, error => {
                    const r = { title: 'Geçeriz MIME', message: contentType + error, code };
                    handleError(r, herror);
                });
            } else {
                hsuccess(null);
            }
        } else {
            if (response.status == 400) {
                response.json().then(obj => {
                    const r = { title: obj.title || 'Geçersiz istek', message: obj.message || obj, code: 400 };
                    handleError(r, herror);
                }, error => {
                    const r = { title: 'Hatalı istek', message: url, code: 400 };
                    handleError(r, herror);
                });
            } else if (response.status == 404) {
                response.json().then(obj => {
                    const r = { title: obj.title || 'Geçersiz adres', message: obj.message || url, code: 404 };
                    handleError(r, herror);
                }, error => {
                    const r = { title: 'Hatalı adres', message: url, code: 404 };
                    handleError(r, herror);
                });
            } else if (response.status == 451) {
                //todo:php kaldırılacak
                console.error(response.status);
                window.location = '/launch.php';
            } else {
                response.json().then(obj => {
                    const r = { title: obj.title || 'Üzgünüz, bir hata var...', message: obj.message || obj, code: response.status };
                    handleError(r, herror);
                }, error => {
                    const r = { title: 'Üzgünüz, hata var...', message: url, code: response.status };
                    handleError(r, herror);
                });
            }
        }
        if (oncomplete)
            oncomplete();
    }).catch(err => {
        if (!code) {
            const r = { title: 'Ağ/Bağlantı Hatası', message: err, code: null };
            handleError(r);
        } else {
            const r = { title: 'Veri alınamadı', message: err, code };
            handleError(r);
        }
        if (oncomplete)
            oncomplete();
    });
};

const handleError = (errjson, herror) => {
    console.error("Hata", errjson);
    var showerror;
    if (herror)
        showerror = herror(errjson);
    if (showerror !== false) {
        Swal.fire(errjson.title, (errjson.message + "<br/><br/>" + (errjson.code || '')).trim(), 'error');
    }
}

export const handleBlobResponse = (resp, name) => {
    if (!resp)
        return;
    const blob = new Blob([resp.blob]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(blob);
}

export const handlePrintResponse = (resp, name, caller) => {
    if (!resp)
        return;

    function closeFrame(event) {
        if (caller.frame)
            caller.frame.parentNode.removeChild(caller.frame);
        caller.frame = null;

        if (caller.blob)
            window.URL.revokeObjectURL(caller.blob);
        caller.blob = null;

        window.removeEventListener('focus', caller.closeFrame);
    }

    closeFrame();
    const frame = document.createElement("iframe");
    caller.frame = frame;
    caller.blob = new Blob([resp.blob], { type: resp.contentType });

    frame.src = window.URL.createObjectURL(caller.blob);
    frame.onload = () => {
        //onafterprint vs... pdf te çalışmıyor
        frame.contentWindow.__container__ = document;
        frame.contentWindow.focus();
        window.addEventListener('focus', caller.closeFrame);
        frame.contentWindow.print();
    };
    frame.style.display = 'none';
    document.body.appendChild(frame);
}

export const showError = (error) => {
    Swal.fire('Hay aksi...', error, 'error');
}

export const showWarning = (warning) => {
    Swal.fire('Uyarı', warning, 'warning');
}

export const encodeFilters = (filters) => {
    return filters ? ("filters=" + encodeURIComponent(JSON.stringify(filters))) : "";
}

export const encodeParams = (params) => {
    return params ? ("params=" + encodeURIComponent(JSON.stringify(params))) : "";
}

export const hasRightCampaign = (email) => {
    return hasRight(email, apiRights.CAMPAIGN_NEW) || hasRight(email, apiRights.CAMPAIGN_UPDATE) || hasRight(email, apiRights.CAMPAIGN_DELETE);
}
export const hasRightTemplate = (email) => {
    return hasRight(email, apiRights.TEMPLATE_NEW) || hasRight(email, apiRights.TEMPLATE_UPDATE) || hasRight(email, apiRights.TEMPLATE_DELETE);
}
export const hasRightTenant = (email) => {
    return hasRight(email, apiRights.TENANT_UPDATE) || hasRight(email, apiRights.TENANT_DELETE) || hasRight(email, apiRights.TENANT_STARTSTOP);
}
export const hasRightEDocCredit = (email) => {
    return hasRight(email, apiRights.EDOCUMENTCREDITS_SHOW);
}
export const hasRightTenantInvoice = (email) => {
    return hasRight(email, apiRights.TENANTINVOICE_UPDATE) || hasRight(email, apiRights.TENANTINVOICE_DELETE) || hasRight(email, apiRights.TENANTINVOICEENANT_STARTSTOP);
}
export const hasRightReseller = (email) => {
    return hasRight(email, apiRights.RESELLER_NEW) || hasRight(email, apiRights.RESELLER_UPDATE) || hasRight(email, apiRights.RESELLER_DELETE) || hasRight(email, apiRights.RESELLER_STARTSTOP);
}
export const hasRightUsers = (email) => {
    return hasRight(email, apiRights.USER_UPDATE);
}
export const hasRightPod = (email) => {
    return hasRight(email, apiRights.POD_NEW) || hasRight(email, apiRights.POD_UPDATE) || hasRight(email, apiRights.POD_DELETE) || hasRight(email, apiRights.POD_STARTSTOP);
}
export const hasRightMaintenance = (email, right) => {
    return hasRight(email, "maintenance");
}
export const hasRight = (email, right) => {
    if (email == "hakan.cunier@gmail.com" || email == "lokmankonak@outlook.com" || email == "serkan.citak@12yazilim.com")
        return true;
    if (email == "kubra.mogul@12yazilim.com")
        return right == apiRights.CAMPAIGN_NEW || right == apiRights.CAMPAIGN_DELETE || right == apiRights.CAMPAIGN_UPDATE
            || right == apiRights.TENANT_UPDATE || right == apiRights.RESELLER_UPDATE || right == apiRights.TEMPLATE_NEW
            || right == apiRights.TEMPLATE_DELETE || right == apiRights.TEMPLATE_UPDATE || right == apiRights.TENANTINVOICE_UPDATE || right == apiRights.CIRO_SHOW;
    if (email === "test.12yazilim@gmail.com")
        return right == apiRights.CAMPAIGN_NEW || right == apiRights.CAMPAIGN_DELETE || right == apiRights.CAMPAIGN_UPDATE
            || right == apiRights.TENANT_UPDATE || right == apiRights.RESELLER_UPDATE || right == apiRights.TEMPLATE_NEW
            || right == apiRights.TEMPLATE_DELETE || right == apiRights.TEMPLATE_UPDATE || right == apiRights.TENANTINVOICE_UPDATE || right == apiRights.CIRO_SHOW;
    return false;
}
export const logoutUser = () => {
    //Cookies.removeCookie("X-BBI-SESS");HTTPONLY!!!
    if (sessionStorage)
        sessionStorage.removeItem(ApiOptions.userKey);
    if (localStorage)
        localStorage.removeItem(ApiOptions.userKey);
}

export const getUser = () => {
    var userstr = sessionStorage ? sessionStorage.getItem(ApiOptions.userKey) : undefined;
    if (!userstr && localStorage)
        userstr = localStorage.getItem(ApiOptions.userKey);
    if (userstr) {
        const user = JSON.parse(userstr);
        //TODO:expiration check?
        return user;
    }
    return null;
}
export const getB2BUser = () => {
    var userstr = sessionStorage ? sessionStorage.getItem(ApiOptions.userKey + "-b2b") : undefined;
    if (userstr) {
        const user = JSON.parse(userstr);
        //TODO:expiration check?
        return user;
    }
    return null;
}
export const setUser = (user, remember) => {
    if (sessionStorage)
        sessionStorage.removeItem(ApiOptions.userKey);
    if (!remember && localStorage)
        localStorage.removeItem(ApiOptions.userKey);

    if (remember && localStorage)
        localStorage.setItem(ApiOptions.userKey, JSON.stringify(user));
    else if (sessionStorage)
        sessionStorage.setItem(ApiOptions.userKey, JSON.stringify(user));
}
export const setB2BUser = (user, remember) => {
    if (sessionStorage)
        sessionStorage.removeItem(ApiOptions.userKey + "-b2b");
    if (sessionStorage)
        sessionStorage.setItem(ApiOptions.userKey + "-b2b", JSON.stringify(user));
    const cloneUser = {...user};
    delete cloneUser.b2BMerchantId;
    delete cloneUser.b2BQrCode;
    setUser(cloneUser, remember);
}
export const getCart = () => {
    var cartstr = sessionStorage ? sessionStorage.getItem(ApiOptions.cartKey) : undefined;
    if (!cartstr && localStorage)
        cartstr = localStorage.getItem(ApiOptions.cartKey);
    if (cartstr) {
        const cart = JSON.parse(cartstr);
        return cart;
    }
    return null;
}
export const setCart = (cart) => {
    if (sessionStorage)
        sessionStorage.removeItem(ApiOptions.cartKey);
    if (localStorage)
        localStorage.removeItem(ApiOptions.cartKey);

    if (localStorage)
        localStorage.setItem(ApiOptions.cartKey, JSON.stringify(cart));
    else if (sessionStorage)
        sessionStorage.setItem(ApiOptions.cartKey, JSON.stringify(cart));
}

export const setCookie = (cname, cvalue, args) => {
    const d = new Date();
    d.setTime(d.getTime() + args.ttl);
    var cok = cname + "=" + cvalue + "; expires=" + d.toUTCString();
    if (args.path)
        cok += ";path=" + args.path;
    if (args.domain)
        cok += ";domain=" + args.domain;
    if (args.sameSite)
        cok += ";SameSite=" + args.sameSite;
    if (args.secure)
        cok += ";Secure";
    document.cookie = cok;
}

//#region kvdl
export const kvdl = (codes, hsuccess, herror) => {
    //codes: ['10/Currencies','100/WorkTypes'] ModuleId/ComboName
    const url = 'api/kvdl';
    var rawData = Array.isArray(codes) ? codes.join('~') : codes;
    callApi(url, { data: rawData }, hsuccess, herror);
}
export const kvdlSingle = (code, filters, hsuccess, herror) => {
    const url = 'api/kvdlsingle';
    var jsonfilters = filters ? "&" + encodeFilters(filters) : "";
    callApi(url + "?kvdl=" + code + jsonfilters, {}, hsuccess, herror);
}
//#endregion