import React from 'react';
import { PureComponent } from 'react';
import { Button as RButton} from 'reactstrap';
import Swal from 'sweetalert2';

export default class Button extends PureComponent {
    
    constructor(props){
        super(props);
        this.state={};
    }

    toggleBusyState = (busy, callback) => {
        if (this.props.showBusy) {
            this.setState({ busy }, callback);
        } else {
            if (callback)
                callback();
        }
    }

    handleClick = (event)=>{
        event.preventDefault();
        if (!this.props.onClick)
            return;
        if (!this.props.confirmation) {
            const that = this;
            this.toggleBusyState(true, () => {
                const result = that.props.onClick(event, that.props.clickArgument ? that.props.clickArgument : null, () => {
                    that.toggleBusyState(false);
                });
                if (result != undefined && !result)
                    that.toggleBusyState(false);
            });
            return;
        }
        Swal.fire({
            title: 'Onay Gerekli',
            text: this.props.confirmation,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Tamam',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'İptal'
        }).then((confirmation) => {
            if (confirmation.value) {
                const that = this;
                this.toggleBusyState(true, () => {
                    const result = that.props.onClick(event, that.props.clickArgument ? that.props.clickArgument : null, () => {
                        that.toggleBusyState(false);
                    });
                    if (result != undefined && !result)
                        that.toggleBusyState(false);
                });
            }
        });
    }

    render(){
        const { onClick, showBusy, disabled, ...others } = this.props;
        return <RButton onClick={this.handleClick} {...others} disabled={this.state.busy === true||disabled}>
            {this.state.busy ? "Lütfen bekleyin..." : (this.props.icon?<i className={this.props.icon}/>:null) }
            {!this.state.busy ? this.props.children : null}
        </RButton>;
    }
}