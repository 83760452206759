import React from "react";
import HomePage from "./page/HomePage";
import eFaturaSoru from "./page/eFaturaSoru";

const MyTenants = React.lazy(() => import("./page/user/MyTenants"));
const Profile = React.lazy(() => import("./page/user/Profile"));
const Register = React.lazy(() => import("./page/user/Register"));
const TenantUserInvitation = React.lazy(() => import("./page/user/TenantUserInvitation"));
const registerreseller = React.lazy(() => import("./page/registerreseller"));
const teklifkoruma = React.lazy(() => import("./page/teklifkoruma"));
const registersalesclerk = React.lazy(() => import("./page/registersalesclerk"));
const Tenants = React.lazy(() => import("./page/admin/Tenants"));
const EDocumentCredits = React.lazy(() => import("./page/admin/EDocumentCredits"));
const MailControl = React.lazy(() => import("./page/admin/MailControl"));
const LegalHoliday = React.lazy(() => import("./page/admin/LegalHoliday"));
const PackageManagement = React.lazy(() => import("./page/admin/PackageManagement"));
const PackageFeature = React.lazy(() => import("./page/admin/PackageFeature"));
const LicenceManagement = React.lazy(() => import("./page/admin/LicenceManagement"));
const LicenseInvoiceLine = React.lazy(() => import("./page/admin/LicenseInvoiceLine"));
const Parameters = React.lazy(() => import("./page/admin/Parameters"));
const TenantStores = React.lazy(() => import("./page/admin/TenantStores"));
const Databases = React.lazy(() => import("./page/admin/Databases"));
const TenantInvoices = React.lazy(() => import("./page/user/TenantInvoices"));
const MyAddress = React.lazy(() => import("./page/user/MyAddress"));
const eArsiv = React.lazy(() => import("./page/eArsiv"));
const eFatura = React.lazy(() => import("./page/eFatura"));
const mobil = React.lazy(() => import("./page/mobil"));
const perakende = React.lazy(() => import("./page/perakende"));
const eticaret = React.lazy(() => import("./page/eticaret"));
const onmuhasebesyf = React.lazy(() => import("./page/onmuhasebesyf"));
const finansyonetimi = React.lazy(() => import("./page/finansyonetimi"));
const personelyonetimi = React.lazy(() => import("./page/personelyonetimi"));
const depostokyonetimi = React.lazy(() => import("./page/depostokyonetimi"));
const siparisyonetimi = React.lazy(() => import("./page/siparisyonetimi"));
const teklifyonetimi = React.lazy(() => import("./page/teklifyonetimi"));
// const taksitli = React.lazy(() => import("./page/taksitli"));
const hakkimizda = React.lazy(() => import("./page/hakkimizda"));
const sektorler = React.lazy(() => import("./page/sektorler"));
const iletisim = React.lazy(() => import("./page/iletisim"));
const eSmm = React.lazy(() => import("./page/eSmm"));
const eIrsaliye = React.lazy(() => import("./page/eIrsaliye"));
const mustahsilmakbuzu = React.lazy(() => import("./page/mustahsilmakbuzu"));
const aydinlatma = React.lazy(() => import("./page/aydinlatma"));
const kisiselveriler = React.lazy(() => import("./page/kisiselveriler"));
const kullanicisozlesmesi = React.lazy(() => import("./page/kullanicisozlesmesi"));
const cerezpolitikasi = React.lazy(() => import("./page/cerezpolitikasi"));
const akademi = React.lazy(() => import("./page/akademi"));
const issozlesmesi = React.lazy(() => import("./page/issozlesmesi"));
const perakendeakademi = React.lazy(() => import("./page/perakendeakademi"));
const finansakademi = React.lazy(() => import("./page/finansakademi"));
const edonusumakademi = React.lazy(() => import("./page/edonusumakademi"));
const bolgebayilistesi = React.lazy(() => import("./page/bolgebayilistesi"));
// const mobil = React.lazy(() => import("./page/mobil"));
// const eIhracat = React.lazy(() => import("./page/eIhracat"));
const PageNotFound = React.lazy(() => import("./page/PageNotFound"));
const Order = React.lazy(() => import("./page/Order"));
const OrderEDocumentCredit = React.lazy(() => import("./page/OrderEDocumentCredit"));
const Reseller = React.lazy(() => import("./page/user/Reseller"));
const ResellerProposals = React.lazy(() => import("./page/user/ResellerProposals"));
const AmazonSPLandingPage = React.lazy(() => import("./page/AmazonSPLandingPage"));

const routes = [
    { path: "/", exact: true, component: HomePage },
    { path: "/index", exact: true, component: HomePage },
    { path: "/efatura", exact: true, component: eFatura },
    { path: "/efaturasoru", exact: true, component: eFaturaSoru },
    { path: "/mobil", exact: true, component: mobil },
    { path: "/earsiv", exact: true, component: eArsiv },
    { path: "/esmm", exact: true, component: eSmm },
    { path: "/eirsaliye", exact: true, component: eIrsaliye },
    { path: "/mustahsilmakbuzu", exact: true, component: mustahsilmakbuzu },
    { path: "/perakende", exact: true, component: perakende },
    { path: "/eticaret", exact: true, component: eticaret },
    { path: "/onmuhasebesyf", exact: true, component: onmuhasebesyf },
    { path: "/finansyonetimi", exact: true, component: finansyonetimi },
    { path: "/personelyonetimi", exact: true, component: personelyonetimi },
    { path: "/depostokyonetimi", exact: true, component: depostokyonetimi },
    { path: "/siparisyonetimi", exact: true, component: siparisyonetimi },
    { path: "/teklifyonetimi", exact: true, component: teklifyonetimi },
    // { path: "/taksitli", exact: true, component: taksitli },
    { path: "/hakkimizda", exact: true, component: hakkimizda },
    { path: "/sektorler", exact: true, component: sektorler },
    { path: "/iletisim", exact: true, component: iletisim },
    { path: "/aydinlatma", exact: true, component: aydinlatma },
    { path: "/kisiselveriler", exact: true, component: kisiselveriler },
    { path: "/kullanicisozlesmesi", exact: true, component: kullanicisozlesmesi },
    { path: "/cerezpolitikasi", exact: true, component: cerezpolitikasi },
    { path: "/akademi", exact: true, component: akademi },
    { path: "/issozlesmesi", exact: true, component: issozlesmesi },
    { path: "/perakendeakademi", exact: true, component: perakendeakademi },
    { path: "/finansakademi", exact: true, component: finansakademi },
    { path: "/edonusumakademi", exact: true, component: edonusumakademi },
    { path: "/bolgebayilistesi", exact: true, component: bolgebayilistesi },
    // { path: "/mobil", exact: true, component: mobil },
    { path: "/mytenants", component: MyTenants },
    { path: "/useraddress", component: MyAddress },
    { path: "/invoices", component: TenantInvoices },
    { path: "/register", component: Register },
    { path: "/registerreseller", component: registerreseller },
    { path: "/teklifkoruma", component: teklifkoruma },
    { path: "/registersalesclerk", component: registersalesclerk },
    { path: "/tuinvitation", component: TenantUserInvitation },
    { path: "/profile", component: Profile },
    // { path: "/eihracat", exact: true, component: eIhracat },
    { path: "/login", component: HomePage },
    { path: "/pagenotfound", component: PageNotFound },
    { path: "/order", component: Order },
    { path: "/orderecredits", component: OrderEDocumentCredit },
    { path: "/reseller", component: Reseller},
    { path: "/resellerproposals", component: ResellerProposals },
    { path: "/amazonlandingpage", component: AmazonSPLandingPage },
    // Admin
    { path: "/tenants", component: Tenants },
    { path: "/edoccredits", component: EDocumentCredits },
    { path: "/mailcontrol", component: MailControl },
    { path: "/legalholiday", component: LegalHoliday },
    { path: "/packagemanagement", component: PackageManagement },
    { path: "/packagefeature", component: PackageFeature },
    { path: "/licencemanagement", component: LicenceManagement },
    { path: "/licenseinvoiceline", component: LicenseInvoiceLine },
    { path: "/parameters", component: Parameters },
    { path: "/tenantstores", component: TenantStores },
    { path: "/databases", component: Databases },
    { path: "/manual", component: () => { window.open('https://12yazilim.com/bulut12-egitim-rehberi/'); window.location = '/'; return null; } },
    { path: "/bayigiris", component: () => { window.open('https://panel.12yazilim.com/'); window.location = '/'; return null; } },
    { path: "/instagram", component: () => { window.open('https://www.instagram.com/bulut12official/'); window.location = '/'; return null; } },
    { path: "/linkedin", component: () => { window.open('https://www.linkedin.com/company/bulut12/'); window.location = '/'; return null; } },
    { path: "/wp", component: () => { window.open('https://wa.me/+905356629612?text= Bulut12 hakkinda bilgi almak istiyorum'); window.location = '/'; return null; } },
    { path: "/egitim", component: () => { window.open('https://12yazilim.com/egitim'); window.location = '/'; return null; } },
    { path: "/burdanbi", component: () => { window.open('https://www.burdanbi.com/'); window.location = '/'; return null; } },
    { path: "/mail", component: () => { window.open('destek@bulut12.com'); window.location = 'iletisim'; return null; } },
    { path: "/satisfaturasibilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=94429598'); window.location = 'edonusumakademi'; return null; } },
    { path: "/satistaniadeaturasibilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=94429861'); window.location = 'edonusumakademi'; return null; } },
    { path: "/alisfaturasibilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=107446552'); window.location = 'edonusumakademi'; return null; } },
    { path: "/alistaniadefaturasibilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=106004502'); window.location = 'edonusumakademi'; return null; } },
    { path: "/satisirsaliyesibilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=94429881'); window.location = 'edonusumakademi'; return null; } },
    { path: "/satistaniadeirsaliyesibilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=94429950'); window.location = 'edonusumakademi'; return null; } },
    { path: "/alisirsaliyesibilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=106004495'); window.location = 'edonusumakademi'; return null; } },
    { path: "/alistaniadeirsaliyesibilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=106004507'); window.location = 'edonusumakademi'; return null; } },
    { path: "/mustahsilmakbuzubilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=106528830'); window.location = 'edonusumakademi'; return null; } },
    { path: "/verilensmmbilgi", component: () => { window.open('https://confluence.12yazilim.com/display/BLT12/Verilen+SMM'); window.location = 'edonusumakademi'; return null; } },
    { path: "/alinansmmbilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=106528832'); window.location = 'edonusumakademi'; return null; } },
    { path: "/satisfisleribilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=106528853'); window.location = 'edonusumakademi'; return null; } },
    { path: "/satisfaturasibilgi", component: () => { window.open('https://confluence.12yazilim.com/pages/viewpage.action?pageId=94429598'); window.location = 'edonusumakademi'; return null; } },

];
export default routes;