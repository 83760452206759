import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import {
  Container,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Button,
  Card,
  CardBody,
  FormFeedback,
  Form,
} from "reactstrap";
import sha256 from 'crypto-js/sha256';
import encBase64 from "crypto-js/enc-base64";
import { activateTenant, signup } from '../../service/backend';
import { getUser, setUser, showError } from '../../service/FetchUtil';
import Swal from "sweetalert2";
import FeatherIcon from "feather-icons-react";
import BaseViewComponent from "../custom/view/BaseViewComponent";
import { checkPasswordStrength, notifyError } from "../custom/util/Util";

// import kullanicisozlesmesi from "../../page/kullanicisozlesmesi";
// import { mode } from "crypto-js";


class SignUp extends BaseViewComponent {
  constructor(props) {
    super(props);
    this.state = { model: {} };
  }

  render() {
    return <div className="cover-user-img d-flex align-items-center">
      <Row>
        <Col xs={12}>
          <Card className="login_page shadow rounded border-0">
            <CardBody>
              <h4 className="card-title text-center">Üye Kayıt</h4>
              <Form className="login-form mt-4" onValidSubmit={this.onValidSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroup className="position-relative">
                      <Label htmlFor="ad">
                        Ad <span className="text-danger">*</span>
                      </Label>
                      <div className="position-relative">
                        <i>
                          <FeatherIcon
                            icon="user"
                            className="fea icon-sm icons"
                          />
                        </i>
                      </div>
                      <Input type="text" testid="txtAdi" id="ad" name="ad" value={this.state.model.ad} onChange={this._handleTextChange} required
                        placeholder="Adınız" ref={r => this.tbAdi = r} autoFocus className="form-control pl-5"
                        invalid={this.state.model.ad == undefined || this.state.model.ad.trim().length < 3} />
                      <FormFeedback>En az 3 karakter olmalıdır</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="position-relative">
                      <Label htmlFor="soyad">
                        Soyad <span className="text-danger">*</span>
                      </Label>
                      <div className="position-relative">
                        <i>
                          <FeatherIcon
                            icon="user-check"
                            className="fea icon-sm icons"
                          />
                        </i>
                      </div>
                      <Input type="text" testid="txtSoyadi" id="soyad" name="soyad" value={this.state.model.soyad} onChange={this._handleTextChange} required
                        placeholder="Soyadınız" ref={r => this.tbSoyadi = r} className="form-control pl-5"
                        invalid={this.state.model.soyad == undefined || this.state.model.soyad.trim().length < 2} />
                      <FormFeedback>En az 2 karakter olmalıdır</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="position-relative">
                      <Label htmlFor="email">
                        E-Posta <span className="text-danger">*</span>
                      </Label>
                      <div className="position-relative">
                        <i>
                          <FeatherIcon
                            icon="mail"
                            className="fea icon-sm icons"
                          />
                        </i>
                      </div>
                      <Input type="email" testid="txtmail" id="eposta" name="eposta" value={this.state.model.eposta} onChange={this._handleTextChange} required
                        placeholder="E-Posta adresiniz" ref={r => this.tbEposta = r} className="form-control pl-5"
                        invalid={this.state.model.eposta == undefined || this.state.model.eposta.trim().length < 5} />
                      <FormFeedback>En az 5 karakter olmalıdır</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col md="12">
                    <FormGroup className="position-relative">
                      <Label htmlFor="password">
                        Şifre<span className="text-danger">*</span>
                      </Label>
                      <div className="position-relative">
                        <i>
                          <FeatherIcon
                            icon="lock"
                            className="fea icon-sm icons"
                          />
                        </i>
                      </div>
                      <Input type="password" testid="txtpass" id="sifre" name="sifre" value={this.state.model.sifre} onChange={this._handleTextChange} onKeyDown={this.handleSignPassword} required
                        placeholder="Şifreniz" ref={r => this.tbSifre = r} className="form-control pl-5"
                        invalid={this.state.model.sifre == undefined || this.state.model.sifre.trim().length < 6} />
                      <FormFeedback>En az 6 karakter olmalıdır</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col md="12">
                    <FormGroup className="position-relative">
                      <Label htmlFor="password">
                        Şifre (Tekrar)
                        <span className="text-danger">*</span>
                      </Label>
                      <div className="position-relative">
                        <i>
                          <FeatherIcon
                            icon="lock"
                            className="fea icon-sm icons"
                          />
                        </i>
                      </div>
                      <Input type="password" testid="txtpasstwo" id="onaysifre" name="onaysifre" value={this.state.model.onaysifre} onChange={this._handleTextChange} onKeyDown={this.handleSignPassword} required
                        placeholder="Şifreniz (Tekrar)" ref={r => this.tbOnaySifre = r} className="form-control pl-5"
                        invalid={this.state.model.onaysifre == undefined || this.state.model.onaysifre.trim().length < 6} />
                      <FormFeedback>En az 6 karakter olmalıdır</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col md="12" className="d-flex justify-content-between">
                    <FormGroup check>
                      <Input required="required" testid="chbUye" type="checkbox" name="contract" id="contract" onChange={this._handleCheckChange} />
                      <Label htmlFor="contract">
                        <Link to="kullanicisozlesmesi" className="text-primary" target="_blank" rel="noopener noreferrer">
                          Üyelik sözleşmesi ve koşullarını
                        </Link>
                        &nbsp;okudum, anladım, kabul ediyorum.
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="12" className="mb-0">
                    <Button showBusy block id="btnkayit" testid="btnkayit"  color="primary"  ref={(r) => this.btnActivateStore = r} onClick={this.handleSignup}>
                      Kayıt
                    </Button>
                  </Col>
                  <Col xs="6" className="text-center">
                    <p className="mb-0 mt-3">
                      <Link
                        to="#"
                        className="text-dark font-weight-bold"
                        onClick={this.props.onLogin}
                      >
                        <small className="text-dark mr-2">
                          Zaten üye misiniz?
                        </small>
                      </Link>
                    </p>
                  </Col>
                  <Col xs="6" className="text-center">
                    <p className="mb-0 mt-3">
                      <Link
                        to="#"
                        className="text-dark font-weight-bold"
                        onClick={this.props.onLogin}
                      >
                        <small className="text-dark mr-2">
                          Yetkilendirme servisi ile kayıt
                        </small>
                      </Link>
                    </p>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>;
  }

  //   handleSignUp = (event) => {
  //     const { model } = this.state;
  //     // if (model.eposta && model.sifre) {
  //     //   if (model.sifre!==model.onaysifre){
  //     //     showError("Şifreler aynı değil");
  //     //     return;
  //     //   }
  //       model.sifre = sha256(model.sifre).toString(encBase64);
  //       delete model.onaysifre;
  //       signup(model, (resp) => {
  //         setUser(resp, model.remember);
  //         window.location.href = "/";
  //       });
  //     }
  //  // };
  handleSignPassword = (event, args, resetBusy) => {
    if (!this.tbEposta.props.invalid && !this.tbSifre.props.invalid && !this.tbOnaySifre.props.invalid) {
      if (event != undefined && event.keyCode != undefined && event.keyCode === 13) {
        //console.log(event.keyCode);
        document.getElementById("btnkayit").click();
      }
    }
  }

  componentDidMount() {
    if (this.tbAdi)
      this.tbAdi.focus();
  }

  handleSignup = (event, args, resetBusy) => {
    const { model } = this.state;
    if (!model.eposta || model.eposta=="") {
      notifyError("Geçerli bir E-Posta adresi gereklidir");
      this.tbEposta.focus();
      return;
    }
    if (!model.sifre || model.sifre=="") {
      notifyError("Şifre boş olamaz");
      this.tbSifre.focus();
      return;
    }
    if (!model.onaysifre || model.onaysifre=="") {
      notifyError("Şifre (Tekrar) boş olamaz");
      this.tbOnaySifre.focus();
      return;
    }
    const passwordResult = checkPasswordStrength(model.sifre);
    if (passwordResult.strength < 3) {
      Swal.fire("Zayıf şifre", passwordResult.tips, "error");
      this.tbSifre.focus();
      return;
    }
    if (model.sifre !== model.onaysifre) {
      Swal.fire("Eksik bilgi", "Şifreler aynı değil", 'error');
      this.tbSifre.focus();
      return;
    }
    if (!model.contract) {
      Swal.fire("Eksik bilgi", " Üyelik sözleşmesi onayı eksik", 'error');
      return;
    }
    if (resetBusy) {
      resetBusy();
    }

    model.sifre = sha256(model.sifre).toString(encBase64);
    model.onaysifre = sha256(model.onaysifre).toString(encBase64);
    // delete model.onaysifre;
    signup(model, (resp) => {
      getUser(resp, model.remember);
      window.location.href = "/";
    });
    if (model.eposta && model.sifre && model.onaysifre && model.contract) {
      signup(model, (resp) => {
        getUser(resp);
        window.location.href = "/";
      }, null, resetBusy);
    } else if (resetBusy)
      resetBusy();
  }

}

export default withRouter(withTranslation()(SignUp));
