import moment from 'moment';
import numeral from 'numeral';
import { setCookie } from './FetchUtil';
import i18n from 'i18next';

export const LocaleContext = {
    currencyFormat: '0,0.00',
    currencyFormat4: '0,0.00[00]',
    decimalFormat: '0,0.0[00]',
    decimalGroupSeparator: ',',
    decimalCharacter: '.',
    apiDateFormat: 'YYYY-MM-DD',
    apiDateTimeFormat: 'YYYY-MM-DD HH:mm',
    uiDateFormat: 'MM.DD.YY',
    uiDateFormat4: 'MM.DD.YYYY',
    uiDateTimeFormat: 'MM.DD.YY HH:mm',
    uiDateTimeFormat4: 'MM.DD.YYYY HH:mm'
};

export const dateBefore = (days) => {
    return moment().subtract(days, 'days');
}

export const dateAfter = (days) => {
    return moment().add(days, 'days');
}

export const formatDate = (date) => {
    return date == undefined || !date ? "" : moment(date).format(LocaleContext.uiDateFormat);
}

export const formatDate4 = (date) => {
    return date == undefined || !date ? "" : moment(date).format(LocaleContext.uiDateFormat4);
}

export const formatDateTime = (date) => {
    return date == undefined || !date ? "" : moment(date).format(LocaleContext.uiDateTimeFormat);
}

export const formatDateTime4 = (date) => {
    return date == undefined || !date ? "" : moment(date).format(LocaleContext.uiDateTimeFormat4);
}

export const formatDateTimeToNow = (date) => {
    return date == undefined || !date ? "" : moment(date).toNow(true);
}

export const formatDecimal = (val) => {
    return numeral(val).format(LocaleContext.decimalFormat);
}

export const formatDecimalNonZero = (val) => {
    return val && val!=0 ? numeral(val).format(LocaleContext.decimalFormat) : "";
}

export const formatCurrency = (val) => {
    return numeral(val).format(LocaleContext.currencyFormat);
}

export const formatCurrencyNonZero = (val) => {
    return val && val != 0 ? numeral(val).format(LocaleContext.currencyFormat) : "";
}

export const formatCurrencyBalance = (val) => {
    return numeral(Math.abs(val)).format(LocaleContext.currencyFormat) + (val < 0 ? " (A)" : (val > 0 ? " (B)" : ""));
}

export const formatCurrencyTL = (val) => {
    return numeral(val).format(LocaleContext.currencyFormat)+" ₺";
}

export const formatCurrencyBalanceTL = (val) => {
    return numeral(Math.abs(val)).format(LocaleContext.currencyFormat) + (val < 0 ? " ₺ (A)" : (val > 0 ? " ₺ (B)":""));
}

export const formatCurrency4 = (val) => {
    return numeral(val).format(LocaleContext.currencyFormat4);
}

export const launchAppPanel = (tcuaid, api, path, qs) => {
    var domain = "localhost";
    if (process.env.NODE_ENV === 'production') {
        domain = ".bulut12." + (window.location.hostname.endsWith(".com") ? "com" : "xyz");
    }
    const args = { path: "/api", domain, ttl: 240000, sameSite:"lax", secure:true };
    localStorage.setItem("bl12.api", api.replace("${domain}", domain.substring(1)));
    setCookie("bl12.tenant", tcuaid, args);
    window.location = (process.env.NODE_ENV === 'production' ? '/app/' : '//localhost:3001') +(path||'')+ "?lng=" + i18n.language + (qs ? "&"+qs : "");
}
