// React Basic and Bootstrap
import React, { Component } from "react";
import { Container, Row, Modal, ModalBody, Col, Card, CardBody, Media, } from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { getUser } from '../service/FetchUtil';
//Import Icons
import Login from "../components/Layout/Login";
import SignUp from "../components/Layout/SignUp";
import { Config } from '../config';

//Pages
import PricingBox from "../components/Shared/PricingBox";

//Import css
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/style.css";

//Import Images
import cloud_1 from "../assets/images/cloud_01.png";
import cloud_2 from "../assets/images/cloud_02.png";
import cloud_3 from "../assets/images/cloud_03.png";
import cloud_4 from "../assets/images/cloud_04.png";
import bg1 from "../../src/assets/images/bulut12/sliderr/hakkimizda2.jpg";
import personel from "../assets/images/bulut12/coksebepvar/personelyonetimi.png";
import muhasebe from "../assets/images/bulut12/coksebepvar/onmuhasebe.png";
import finans from "../assets/images/bulut12/coksebepvar/finans.png";
import depo from "../assets/images/bulut12/coksebepvar/depoyonetimi.png";
import entegrasyon from "../assets/images/bulut12/coksebepvar/entegrasyonlar.png";
import burdanbi from "../assets/images/bulut12/coksebepvar/burdanbi.png";
import posguru from "../assets/images/bulut12/coksebepvar/posguru.png";
import perakende from "../assets/images/bulut12/coksebepvar/perakende.png";
import kullanim from "../assets/images/bulut12/nedenbulut12/kolaykullanim.gif";
import kullanici from "../assets/images/bulut12/nedenbulut12/kullaniciodakli.gif";
import erisim from "../assets/images/bulut12/nedenbulut12/heryerdenerisim.gif";
import guvenilir from "../assets/images/bulut12/nedenbulut12/guvenilirhizmet.gif";
import ekip from "../assets/images/bulut12/nedenbulut12/tecrubeliekip.gif";
import cozum from "../assets/images/bulut12/nedenbulut12/biraradacozumler.gif";
import arrow3 from "../assets/images/bulut12/arrow3.png";
import stokgif from "../assets/images/bulut12/stok.gif";
import finansgif from "../assets/images/bulut12/finans.gif";
import personelgif from "../assets/images/bulut12/personel.gif";
import eticaretgif from "../assets/images/bulut12/eticaret.gif";
import perakendegif from "../assets/images/bulut12/perakende.gif";
import teklifgif from "../assets/images/bulut12/teklif.gif";
import siparisgif from "../assets/images/bulut12/siparis.gif";
import edonusumgif from "../assets/images/bulut12/edonusum.gif";
import burdanbigif from "../assets/images/bulut12/burdanbi.gif";
import satisfaturasi from "../assets/images/bulut12/satisfaturasilaptop2.png";

//client

import entegrasyonn from "../assets/images/bulut12/eticaretentegrasyonlari.png";
import arroww from "../assets/images/bulut12/1.png";
import slider2 from "../assets/images/bulut12/altslider/2.png";
import fullfil from "../assets/images/bulut12/altslider/fulfillmentt.png";
import slider3 from "../assets/images/bulut12/altslider/3.png";
import slider4 from "../assets/images/bulut12/altslider/4.png";
import slider5 from "../assets/images/bulut12/altslider/5.png";
import slider6 from "../assets/images/bulut12/altslider/6.png";
import { HashLink } from "react-router-hash-link";
// import { Pricings } from "../config/pricingOptions";


class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loginModal: false,
      signUpModal: false,
      items: [
        {
          image: bg1,
          class: "slider-rtl-2",
          h3: "İşletmenizi Kolayca Yönetin!",
          // p: "Finansal Durumunu",
          // p2: "Kolayca Takip Edin!",
          text: "Online ERP uygulaması Bulut12 ile işletmenizi internetin olduğu her yerden kolayca takip edin ve yönetin!",
          link: "#",
        },
        // {
        // //   image: bg2,
        // //   class: "slider-rtl-3",
        // //   h3: "Tek ekran",
        // //   p: "Üzerinden Birçok İşlemi",
        // //   p2: "Kolayca Yönetin!",
        // //   text: "Tüm ön muhasebe işlemlerinizi tek bir program ile tek bir ekrandan yönetin, zamanın değerini bilin!",
        // //   link: "#",
        // // },
      ],
      features: [
        {
          id: 1,
          image: perakende,
          icon: "uil uil-airplay d-block rounded h3 mb-0",
          heading: "CARİ HESAP TAKİBİ",
          text: "Toptan ve Perakende operasyonlarınızda, ürünlerinizin satın alınışından kasaya kadar olan süreçlerini anlık olarak takip edin, işletmenizin kârlılığını arttırın!",

        },

        {
          id: 2,
          image: muhasebe,
          icon: "uil uil-credit-card-search d-block rounded h3 mb-0",
          heading: "FATURA TAKİBİ",
          text: "Kullanıcı odaklı, kolay ara yüz tasarımı ile ön muhasebe işlemlerinizi pratik bir şekilde sorun yaşamadan yönetin.",

        },
        {
          id: 3,
          image: finans,
          icon: "uil uil-clipboard-alt d-block rounded h3 mb-0",
          heading: "GELİR-GİDER TAKİBİ",
          text: "İşletmenizin tahsilat, ödeme ve diğer mali işlemlerini  anlık olarak takip edebileceğiniz gibi, tanımladığınız banka bilgileriniz sayesinde de hesap hareketlerinizi kolayca görüntüleyebilirsiniz.",

        },
        {
          id: 4,
          image: personel,
          icon: "uil uil-ruler-combined d-block rounded h3 mb-0",
          heading: "MASRAF KARTLARI",
          text: "Saha içi sıcak satışta bulunan personellerinizin bilgilerine kolayca erişme imkanını sağlarken, aynı zamanda da personellerinize ait tüm ödeme ve tahsilat işlemlerini tek ekrandan takip edin.",

        },
      ],
      features2: [
        {
          id: 1,
          image: depo,
          icon: "uil uil-rocket",
          heading: "ÇEK-SENET",
          text: "Depo ve Stok Takibi'nizi pratik bir şekilde yöneterek, anlık olarak ürünlerinizin güncel durumunu kontrol edin. Siparişlerinizin yönetiminde sorun yaşamayın!",

        },
        {
          id: 2,
          image: entegrasyon,
          icon: "uil uil-rocket",
          heading: "KASA-BANKA",
          text: "e-Ticaret platformlarından mağazanız adına aldığınız siparişleri entegrasyon sayesinde kolayca Bulut12'ye aktarın,hızlıca faturalayın.",

        },


        {
          id: 3,
          image: burdanbi,
          icon: "uil uil-file-alt",
          heading: "BURDANBİ",
          text: "Aklında ne varsa hızlı sipariş için sadece BurdanBi yeterli. İster webden ister uygulamadan.",

        },
        {
          id: 4,
          image: posguru,
          icon: "uil uil-search",
          heading: "POSGURU",
          text: "Eski nesil yazarkasa, Pc Pos, Android cihazlarda da  dönüşümleri sağlayan cihaz türü farketmeksizin Market ve Restoran Otomasyon Çözümleri sunan yazılım ürünüdür.",

        },


        //{
        //    id: 9,
        //    icon: "uil uil-search",
        //    heading: "Özet Tablosu",
        //    desc: "Özet tablosundan işletmenize ait finans hareketlerinizi ve satış analizlerinizi takip edebilirsiniz.",

        //},
      ],
      features3: [
        {
          id: 1,
          image: stokgif,
          heading: "Depo ve Stok Yönetimi",
          text: "Depo ve Stok Takibi'nizi pratik bir şekilde yöneterek, ürünlerinizin güncel durumunu kontrol edin.",
          link: "/depostokyonetimi",
        },

        {
          id: 2,
          image: finansgif,
          icon: "uil uil-credit-card-search d-block rounded h3 mb-0",
          heading: "Finans Hareketleri",
          text: "İşletmenizin tahsilat, ödeme ve diğer mali işlem hareketlerini güncel olarak görüntüleyin.",
          link: "/finansyonetimi",
        },
        {
          id: 3,
          image: perakendegif,
          icon: "uil uil-clipboard-alt d-block rounded h3 mb-0",
          heading: "Perakende Yönetimi",
          text: "Tüm operasyon süreçlerinizi ve stok hacminizdeki büyümeyi takip edip, kâr oranlarınızı artırın!",
          link: "/perakende",
        },
        {
          id: 4,
          image: personelgif,
          icon: "uil uil-ruler-combined d-block rounded h3 mb-0",
          heading: "Personel Takibi",
          text: "Personelinize ait tüm ödeme, tahsilat, izin ve bordro işlemlerini tek ekrandan takip edip, yönetebilirsiniz.",
          link: "/personelyonetimi",
        },
        {
          id: 5,
          image: eticaretgif,
          icon: "uil uil-ruler-combined d-block rounded h3 mb-0",
          heading: "E-Ticaret Entegrasyonu",
          text: "e-Ticaret platformlarından mağazanız adına aldığınız siparişleri veya iadeleri kolayca Bulut12'ye aktarın.",
          link: "/eticaret",
        },
        {
          id: 6,
          image: edonusumgif,
          icon: "uil uil-ruler-combined d-block rounded h3 mb-0",
          heading: "E-Dönüşüm",
          text: "Tüm resmi evraklarınızı ve faturalarınızı tek tuşla oluşturun. Dijital ortamda kaybolma derdi olmadan arşivleyin.",
          link: "/#",
        },
        {
          id: 7,
          image: teklifgif,
          icon: "uil uil-ruler-combined d-block rounded h3 mb-0",
          heading: "Teklif Yönetimi",
          text: "Satış faaliyetlerinizi bir bütün olarak takip edin ve onaylanan tekliflerinizi tek tuşla siparişe veya satış faturasına dönüştürün.",
          link: "/teklifyonetimi",
        },
        {
          id: 8,
          image: siparisgif,
          icon: "uil uil-ruler-combined d-block rounded h3 mb-0",
          heading: "Sipariş Yönetimi",
          text: "Sipariş verdiğiniz ürünlerin yönetimini tek ekranda takip edin, tedarik zincirinizi aksatmayın, sorun yaşamayın.",
          link: "/siparisyonetimi",
        },
        {
          id: 9,
          image: burdanbigif,
          icon: "uil uil-ruler-combined d-block rounded h3 mb-0",
          heading: "BurdanBi",
          text: "İster webden, ister uygulamadan. Siz de markanızın ürünlerini BurdanBi üzerinden satın, avantajlardan yararlanın.",
          link: "/burdanbi",
        },
      ],
      // pricings: Pricings(),

      isOpen: false,
      collapse1: false,
      col1: false,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      col6: false,

      step1: true,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
    };
    this.openModal = this.openModal.bind(this);
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
    this.t_col5 = this.t_col5.bind(this);
    this.t_col6 = this.t_col6.bind(this);
    this.t_col7 = this.t_col7.bind(this);
    this.t_col8 = this.t_col8.bind(this);
    this.t_col9 = this.t_col9.bind(this);
    this.states =
    {
      successMsg: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ successMsg: true });
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  toggleLoginModal = () => {
    this.setState((prevState) => ({
      loginModal: !prevState.loginModal,
      signUpModal: false
    }));
  };

  toggleSignUpModal = () => {
    this.setState((prevState) => ({
      loginModal: false,
      signUpModal: !prevState.signUpModal,
    }));
  };
  state = { show: true, }
  toggle = () => this.setState((currentState) => ({ show: !currentState.show }));
  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
    });
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
    });
  }
  t_col3() {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
    });
  }
  t_col4() {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
    });
  }
  t_col5() {
    this.setState({
      col5: !this.state.col5,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
      col6: false,
      col7: false,
      col8: false,
      col9: false,
    });
  }

  t_col6() {
    this.setState({
      col6: !this.state.col6,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
      col5: false,
      col7: false,
      col8: false,
      col9: false,
    });
  }

  t_col7() {
    this.setState({
      col7: !this.state.col7,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
      col5: false,
      col6: false,
      col8: false,
      col9: false,
    });
  }

  t_col8() {
    this.setState({
      col8: !this.state.col8,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col9: false,
    });
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
      col5: false,
      col6: false,
      col7: false,
      col8: false,
    });
  }


  componentDidMount() {
    var e1 = document.getElementsByClassName("slick-slide");
    for (var i = 0; i < 2; i++) {
      if (i === 0) e1[i].style.backgroundImage = `url(${bg1})`; //Kıvırcık
      // if (i === 1) e1[i].style.backgroundImage = `url(${bg2})`;
    }
  }
  render() {
    const user = getUser();
    var settings = {
      lazyLoad: true,
      autoplay: true,
      autoplaySpeed: 7000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      pauseOnHover: false,
      showArrows: true,
      infinite: true,


    };
    const settings2 = {
      dots: true,
      infinite: true,
      autoplaySpeed: 4000,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 3,
    };
    const settings3 = {
      dots: true,
      infinite: true,
      autoplaySpeed: 4000,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 3,
    };
    const settings4 = {
      dots: true,
      infinite: true,
      autoplaySpeed: 4000,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 3,
    };


    return (

      <React.Fragment>
        <section className="main-slider erp_banner_area_two">
          <Slider className="slides"{...settings}>
            {this.state.items.map((item, key) => (
              <li
                key={key}
                className={"bg-slider d-flex align-items-center " + item.class}
              >
                <Container>
                  <ul class="list-unstyled cloud_animation">
                    <li><img src={cloud_1} alt="" /></li>
                    <li><img src={cloud_2} alt="" /></li>
                    <li><img src={cloud_3} alt="" /></li>
                    <li><img src={cloud_4} alt="" /></li>
                  </ul>
                  <Row className="slider-top align-items-center col-lg-12 col-md-6">
                    <Col className="slider-desc">
                      <div className="title-heading">
                        <p className="home-slider1 pera-h1 title-white font-weight-bold h1test">
                          {item.h3} {item.p} {item.p2}
                        </p>
                        <p className="para-desc font-weight-500 para-dark">
                          {item.text}
                        </p>
                        <div className="mt-4 slider-btn">
                          {user ?
                            (!user.data ? <HashLink to="#prices"
                              testid="btnregister"
                              className="btn btn-stildetay btn-primary ml-10  font-weight-500 mb-3 rounded-pill"
                            >
                              7 Gün Ücretsiz Deneyin
                            </HashLink> : null) :
                            <HashLink to="#prices"
                              testid="btnregister"
                              className="btn btn-stildetay btn-primary ml-10  font-weight-500 mb-3 rounded-pill"
                              onClick={this.toggleLoginModal}
                            >
                              7 Gün Ücretsiz Deneyin
                            </HashLink>
                          }
                          <Link to="/wp" className="btn btn-detayli btnstildetay btn-outline-primary  font-weight-500 mb-3 rounded-pill">
                            <i className="uil uil-whatsapp"></i>&nbsp;Detaylı Bilgi
                          </Link>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <a className="btn btn-outline-secondary font-weight-500 mb-3 rounded-pill btnstilnum" href="tel:08503020304">
                          <i className="uil uil-phone"></i>&nbsp;0(850) 302 0304</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </li>
            ))}
          </Slider>
        </section>
        <Container className="baslik container-neden">
          <Row className="justify-content-center">
            <div className="col-12 text-center">
              {/* render Section title */}
              <h3 className="ndnblt12">NEDEN BULUT12?</h3>
              <p className="ndnblt-p">Bulut12 ile işletmenizin yönetimini kolayca gerçekleştirmek için bu avantajlara bir göz atın.</p>
            </div>
            <Col className="col-ndn" lg={12}>
              <Row>
                <Col md={2} xs={6} sm={4} className="mt-4">
                  <div className="features text-center">
                    <small>
                      <img className="ndnblt-icon" src={kullanici} />
                      <p className="baslik text-center  mt-2">
                        Kullanıcı Odaklı
                      </p>
                    </small>
                  </div>
                </Col>
                <Col md={2} xs={6} sm={4} className="mt-4">
                  <div className="features text-center">
                    <small>
                      <img className="ndnblt-icon" src={kullanim} />
                      <p className="baslik text-center  mt-2">
                        Kolay Kullanım
                      </p>
                    </small>
                  </div>
                </Col>
                <Col md={2} xs={6} sm={4} className="mt-4">
                  <div className="features text-center">
                    <small>
                      <img className="ndnblt-icon" src={erisim} />
                      <p className="baslik text-center  mt-2">
                        Her Yerden Erişim
                      </p>
                    </small>
                  </div>
                </Col>
                <Col md={2} xs={6} sm={4} className="mt-4">
                  <div className="features text-center">
                    <small>
                      <img className="ndnblt-icon" src={guvenilir} />
                      <p className="baslik text-center  mt-2">
                        Güvenilir Hizmet
                      </p>
                    </small>
                  </div>
                </Col>
                <Col md={2} xs={6} sm={4} className="mt-4">
                  <div className="features text-center">
                    <small>
                      <img className="ndnblt-icon" src={ekip} />
                      <p className="baslik text-center  mt-2">
                        Tecrübeli Ekip
                      </p>
                    </small>
                  </div>
                </Col>
                <Col md={2} xs={6} sm={4} className="mt-4">
                  <div className="features text-center">
                    <small>
                      <img className="ndnblt-icon" src={cozum} />
                      <p className="baslik text-center mt-2">
                        Bir Arada Çözümler
                      </p>
                    </small>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <h3 className="mt-5 pt-5 verimerkezi justify-content-center">En Güvenilir Veri Merkezi İle Çalışıyoruz</h3>
            <p className="pveri p-3 mb-0">Türkiye’deki en büyük Tier 3 sertifikasına sahip veri merkezi ile çalışmaktayız. Güçlü teknolojisi ve üstün network
              altyapısı sayesinde <a href="https://www.turkcell.com.tr/kurumsal/dijital-is-servisleri/veri-merkezi-data-center" target="_blank">Turkcell Temelli Veri Merkezi</a>, Bulut12 müşterilerine üstün güvenlikli hizmetler sunmaktadır.</p>
          </Row>
        </Container>
        <section className="section bg responsive">
          <Container style={{ position: "relative", left: '-4%' }}>
            <Col className="ednsm-area" xl={12}>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
              <img className="ednsmfatura" src={satisfaturasi} alt="" />
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                  <div className="section-title mr-lg-2 p-8 mb-4">
                    <h3 className="ednsm-baslik">
                      E-DÖNÜŞÜM
                      ADINA TÜM ÇÖZÜMLER
                      İÇİN BULUT12!
                    </h3>
                    <p className="etext">
                      Faturalarınızı tek tıkla oluşturun ve müşterilerinize anında gönderin. Ayrıca faturalarınızı dijital ortamda muhafaza ederek
                      arşivleme çağını geride bırakıp, zamandan tasarruf edebilirsiniz.
                    </p>
                  </div>
                  <Row>
                  <Col xl={4} lg={4} md={4} sm={4} className="buttontest1">
                  <Link to="/efatura"  className="btn btn-ed ml-8 mt-3  font-weight-bold buttontest rounded-pill">
                    e-Fatura
                    <img className="buttontest text" src={arrow3} />
                  </Link><br />
                  <Link to="/earsiv"  className="btn btn-ed ml-8 mt-3  font-weight-bold buttontest  rounded-pill">
                    e-Arşiv
                    <img className="buttontest text" src={arrow3} />
                  </Link>
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} className="buttontest1">
                  <Link to="/eirsaliye"  className="btn btn-ed font-weight-bold mt-3 buttontest  rounded-pill">
                    e-İrsaliye
                    <img className="buttontest text" src={arrow3} />
                  </Link><br />
                  <Link to="/esmm"  className="btn btn-ed ml-8  mt-3 font-weight-bold buttontest rounded-pill"
                  data-toggle="tooltip" data-placement="top" title="e-Serbest Meslek Makbuzu">
                    e-SMM
                    <img className="buttontest text" src={arrow3} />
                  </Link>
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} className="buttontest1">
                  <Link to="/mustahsilmakbuzu" style={{ paddingLeft: '20px' }} className="btn btn-ed font-weight-bold buttontest mt-3 rounded-pill"
                  data-toggle="tooltip" data-placement="top" title="e-Müstahsil Makbuzu">
                    e-MM
                    <img className="buttontest text" src={arrow3} />
                  </Link><br />
                  <Link to="/wp" className="btn btn-ed font-weight-bold buttontest mt-3 rounded-pill">
                    Detaylı Bilgi
                  </Link>
                </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="align-items-center">
                
              </Row>
            </Col>
          </Container>
        </section>
        <section style={{ background: "#ffffff" }}>
          <Container className="justify-content-center">
            <Row className='row justify-content-center mt-0 p-5 uclu-col'>
              <p className="mt-4 h3test-nlr text-l">Bulut12 ile Neler Yapabilirsiniz?</p>
              <p className="text-l nlr-p mt-2">Fatura Yönetimi, Cari Hesap Kontrolü, Kasa-Banka Takibi gibi işlemleri hızlı bir şekilde yapabilmenizin yanı sıra
                Depo ve Stok bilgilerinizi de kolaylıkla yönetebilirsiniz. Bulut sistemine taşımış olduğunuz bu bilgilere ise dilediğiniz zaman, dilediğiniz yerden erişim imkanı sağlarsınız.
              </p>
              <Row className="mt-5 bulut-nlrrow">
                {this.state.features3.map((items, key) => (
                  <Col className="text-align-left" xl={4} lg={4} md={6} xs={12} sm={12}>
                    <Card className="mt-3 mb-3 bulut-nlr" style={{ border: "none" }}>
                      <CardBody>
                        <img className="img-modul" style={{ width: '60px', height: '60px' }} src={items.image} />
                        <h4 className="mt-4" style={{ color: '#4a332d' }}>{items.heading}</h4>
                        <p className="pera-p" style={{ textAlign: '' }}>{items.text}</p>
                        <span>
                          <img className="mb-2" src={arroww} />
                          <Link to={items.link} className="link ml-2 explore-btn" style={{ color: '#223d7f', fontSize: '18px' }}>
                            <a href={items.link}>KEŞFET{" "}</a>
                          </Link>
                        </span>
                      </CardBody>
                    </Card>
                  </Col>))}
              </Row>
              <div className="mt-4 nlr-button" style={{}}>
                {user && user.data ? null :
                  <HashLink to="/index#prices" className="ondort-btn btn btn-outline-primary font-weight-500 mb-3 rounded-pill active">
                    7 Gün Ücretsiz Deneyin
                  </HashLink>
                }
                {user && user.data ? null :
                  <text className="ml-4 mr-1 text-dark yada">ya da</text>
                }
                {user && user.data ?
                  <Link to="/wp" style={{ position: 'relative', left: '35% ' }} className="bizimle-ile-btn btn btn-outline-primary  font-weight-500 mb-3  rounded-pill">
                    Bizimle İletişime Geçin
                  </Link> :
                  <Link to="/wp" className="bizimle-ile-btn btn btn-outline-primary  font-weight-500 mb-3  rounded-pill">
                    Bizimle İletişime Geçin
                  </Link>}
                <p style={{ color: 'gray' }} className="ucretsizp">(Ücretsiz deneme sürecinde kredi kartı bilgisi istenmez.)</p>
              </div>
            </Row>
          </Container>
        </section>
        <section className="section bg p-4 mt-4">
          <Container>
            <Row className="justify-content-center row p-4 mt-3 mb-4">
              <div className="col-12 text-center">
                <h2 className="daha-h3test ">DAHA NELER VAR?</h2>
                <p className="daha-ptest pt-3">Perakende'den Finans'a, Personel Yönetimi'nden Depo-Stok Takibi'ne, Pazaryeri Entegrasyonu'ndan e-Ticaret'e kadar tüm işlemlerinizde en kapsamlı yönetim paneline sahip Bulut12 ERP'yi kullanarak, ayrıcalıkları yakalayın.</p>
              </div>
              <Row className="justify-content-center">
                <Col lg="12" className="mt-3 pt-2 text-center">
                  <div id="owl-fade" className="owl-carousel owl-theme">
                    <div className="owl-stage-outer">
                      <div className="owl-stage">
                        <Row className="justify-content-center dn-iki">
                          {this.state.step1 === true ? (
                            <Container className="cerceve rounded m-2 p-2">
                              <Row className="align-items-center m-2 p-2 dahaneler">
                                <Col
                                  lg={6}
                                  md={7}
                                  className="flex-grow-0"
                                >
                                  <Card className="rounded-md text-left tekyerden" style={{ border: 'none' }}>
                                    <CardBody className="card-stil rounded-md" style={{ backgroundColor: 'white' }}>
                                      <h3 className="baslik">
                                        Tek Yerden E-Ticaret <br />Sistemlerinizi Yönetin!
                                      </h3>
                                      <p className="pslider">
                                        BurdanBi, N11, Hepsiburada, Çiçeksepeti, Trendyol <br/> gibi
                                        e-Ticaret platformları üzerinden ya da bireysel sitelerinizden gelen siparişler
                                        ve fatura işlemleri artık Bulut12'de.
                                      </p>
                                      <ul className="list-unstyled footer-list mt-4">
                                        <li>
                                          Her Yerden Erişin
                                        </li>
                                        <li>
                                          İş Yükünüzü Azaltın
                                        </li>
                                        <li>
                                          Tek Ekrandan Yönetin
                                        </li>
                                        <li className="arrow-img mt-4">
                                          <img className="mb-2" src={arroww} />
                                          <Link to={"eticaret"} className="link ml-2" style={{ color: '#f77e66' }}>
                                            KEŞFET{" "}
                                          </Link>
                                        </li>
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col lg="6" md="5">
                                  <img className="mr-4 dhimg" height="240px" src={entegrasyonn} alt="" />
                                </Col>
                              </Row>
                            </Container>
                          ) : null}
                          {this.state.step2 === true ? (
                            <Container className="border rounded m-2 p-2" style={{ position: "relative", backgroundColor: 'white' }}>
                              <Row className="align-items-center p-3">
                                <Col lg="6" md="5">
                                  <img className="dhimg-laptop" src={fullfil} alt="" />
                                </Col>
                                <Col
                                  lg={6} md={7}
                                  className="flex-grow-0"
                                >
                                  <Card className="rounded-md text-left bg eticaretfull">
                                    <CardBody className="card-stil rounded-md" >
                                      <h3 className="baslik">
                                        E-Ticaret'te Fulfillment Yönetiminin Avantajları
                                      </h3>
                                      <p className="pslider">
                                        Satışa hazır bir e-Ticaret sistemi hız ve kolaylık arz eder.
                                        Satıştaki ürünlerinizin, siparişlerinizin ve dağıtım süreçlerinizin sorunsuz ilerlemesini
                                        istiyorsanız, hemen denemeye başlayın.
                                      </p>
                                      <ul className="list-unstyled footer-list mt-4">
                                        <li>
                                          Her Yerden Erişin
                                        </li>
                                        <li>
                                          İş Yükünüzü Azaltın
                                        </li>
                                        <li>
                                          Tek Ekrandan Yönetin
                                        </li>
                                        <li className="arrow-img mt-4">
                                          <img  src={arroww} />
                                          <Link to={"eticaret"} className="link ml-2" style={{ color: '#f77e66' }}>
                                            KEŞFET{" "}
                                          </Link>
                                        </li>
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </Container>
                          ) : null}
                          {this.state.step3 === true ? (
                            <Container className="cerceve rounded m-2 p-2" style={{ position: "relative" }}>
                              <Row className="align-items-center m-2 p-2">
                                <Col
                                  lg={6} md={7}
                                  className="flex-grow-0"
                                >
                                  <Card className="rounded-md text-left tekyerden-iki" style={{ border: 'none' }}>
                                    <CardBody className="card-stil rounded-md" style={{ backgroundColor: 'white', border: 'none' }} >
                                      <h3 className="baslik">
                                        Personel Yönetim Süreciniz Hız Kazansın!
                                      </h3>
                                      <p className="pslider">
                                        Çok yönlü işleyişi olan Personel Yönetimi, oldukça karmaşık bir süreçtir.
                                        Personeliniz ile ilgili tüm Sicil İşlemleri'ni, Puantaj Takibi'ni, Bordro İşlemeleri'ni
                                        kapsamlı bir şekilde yönetebilmek çok kolay.
                                      </p>
                                      <ul className="list-unstyled footer-list mt-4">
                                        <li>
                                          Her Yerden Erişin
                                        </li>
                                        <li>
                                          İş Yükünüzü Azaltın
                                        </li>
                                        <li>
                                          Tek Ekrandan Yönetin
                                        </li>
                                        <li className="arrow-img mt-4">
                                          <img  src={arroww} />
                                          <Link to={"personelyonetimi"} className="link ml-2" style={{ color: '#f77e66' }}>
                                            KEŞFET{" "}
                                          </Link>
                                        </li>
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col lg="6" md="5">
                                  <img className="mr-4 dhimg-laptop" src={slider2} alt="" />
                                </Col>
                              </Row>
                            </Container>
                          ) : null}
                          {this.state.step4 === true ? (
                            <Container className="border rounded m-2 p-2" style={{ position: "relative", backgroundColor: 'white' }}>
                              <Row className="align-items-center p-3">
                                <Col lg="6" md="5">
                                  <img className="mr-4 dhimg-laptop" src={slider4} alt="" />
                                </Col>
                                <Col
                                  lg={6} md={7}
                                  className="flex-grow-0"
                                >
                                  <Card className="rounded-md text-left deposayim" style={{ backgroundColor: '#e4f2f9', border: 'none' }} >
                                    <CardBody className="card-stil rounded-md" >
                                      <h3 className="baslik">
                                        Depo Sayım ve Stok<br />Kontrol İşlemlerinde Artan Verimlilik!
                                      </h3>
                                      <p className="pslider">
                                        Depolarınızdan, dilediğiniz an güncel stok bilgilerinize erişin.
                                        Yetersiz envanter sebebi ile tek bir siparişi bile kaçırmayın.
                                      </p>
                                      <ul className="list-unstyled footer-list mt-4">
                                        <li>
                                          Her Yerden Erişin
                                        </li>
                                        <li>
                                          İş Yükünüzü Azaltın
                                        </li>
                                        <li>
                                          Tek Ekrandan Yönetin
                                        </li>
                                        <li className="arrow-img mt-4">
                                          <img  src={arroww} />
                                          <Link to={"depostokyonetimi"} className="link ml-2" style={{ color: '#f77e66' }}>
                                            KEŞFET{" "}
                                          </Link>
                                        </li>
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </Container>
                          ) : null}
                          {this.state.step5 === true ? (
                            <Container className="cerceve rounded m-2 p-2" style={{ position: "relative" }}>
                              <Row className="align-items-center m-2 p-2">
                                <Col
                                  lg={6} md={7}
                                  className="flex-grow-0"
                                >
                                  <Card className="rounded-md text-left tekyerden-iki" style={{ backgroundColor: 'white', border: 'none' }}>
                                    <CardBody className="card-stil rounded-md">
                                      <h3 className="baslik">
                                        Stok Takip İşlemleri'nizi Tek Ekrandan Yönetin!
                                      </h3>
                                      <p className="pslider">
                                        Alış-Satış İşlemleri'niz gerçekleştikten hemen sonra stoklarınız otomatik
                                        olarak güncellenir. Bu sayede yetersiz stok ve aşırı birikme gibi
                                        sorunlarla karşılaşmazsınız.
                                      </p>
                                      <ul className="list-unstyled footer-list mt-4">
                                        <li>
                                          Her Yerden Erişin
                                        </li>
                                        <li>
                                          İş Yükünüzü Azaltın
                                        </li>
                                        <li>
                                          Tek Ekrandan Yönetin
                                        </li>
                                        <li className="arrow-img mt-4">
                                          <img  src={arroww} />
                                          <Link to={"depostokyonetimi"} className="link ml-2" style={{ color: '#f77e66' }}>
                                            KEŞFET{" "}
                                          </Link>
                                        </li>
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col lg="6" md="5">
                                  <img className="mr-4 dhimg-laptop" src={slider3} alt="" />
                                </Col>
                              </Row>
                            </Container>
                          ) : null}
                          {this.state.step6 === true ? (
                            <Container className="border rounded m-2 p-2" style={{ position: "relative", backgroundColor: 'white' }}>
                              <Row className="align-items-center m-2 p-2">
                                <Col lg="6" md="5">
                                  <img className="mr-4 dhimg-laptop" src={slider5} alt="" />
                                </Col>
                                <Col
                                  lg={6} md={7}
                                  className="flex-grow-0"
                                >
                                  <Card className="rounded-md  text-left" style={{ backgroundColor: '#e4f2f9', border: 'none' }}>
                                    <CardBody className="card-stil rounded-md" >
                                      <h3 className="baslik">
                                        Eski Nesil Yazar Kasalarda, PosGuru ile Dönüşüm Fırsatı!
                                      </h3>
                                      <p className="pslider">
                                        Eski nesil yazar kasa, Pc Pos ve Android cihazlarda yeni nesil dönüşümleri sağlayan,
                                        cihaz türü farketmeksizin Market ve Restoran Otomasyon çözümleri
                                        sunan yazılım ürünüdür.
                                      </p>
                                      <ul className="list-unstyled footer-list mt-4">
                                        <li>
                                          Her Yerden Erişin
                                        </li>
                                        <li>
                                          İş Yükünüzü Azaltın
                                        </li>
                                        <li>
                                          Tek Ekrandan Yönetin
                                        </li>
                                        <li className="arrow-img mt-4">
                                          <img  src={arroww} />
                                          <Link to={"pagenotfound"} className="link ml-2" style={{ color: '#f77e66' }}>
                                            KEŞFET{" "}
                                          </Link>
                                        </li>
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </Container>
                          ) : null}
                          {this.state.step7 === true ? (
                            <Container className="cerceve rounded m-2 p-2" style={{ position: "relative" }}>
                              <Row className="align-items-center m-2 p-2">
                                <Col
                                  lg={6} md={7}
                                  className="flex-grow-0"
                                >
                                  <Card className="rounded-md text-left tekyerden-iki" style={{ backgroundColor: 'white', border: 'none' }}>
                                    <CardBody className="card-stil rounded-md" >
                                      <h3 className="baslik">
                                        BurdanBi, Alışverişin<br />Yeni Adresi!
                                      </h3>
                                      <p className="pslider">
                                        Market, restoran, fırın, butik, kuyumcu, kasap... Aklınızda ne varsa
                                        hızlı sipariş için sadece BurdanBi yeterli. İster webden, ister uygulmadan.
                                      </p>
                                      <ul className="list-unstyled footer-list mt-4">
                                        <li>
                                          Her Yerden Erişin
                                        </li>
                                        <li>
                                          İş Yükünüzü Azaltın
                                        </li>
                                        <li>
                                          Tek Ekrandan Yönetin
                                        </li>
                                        <li className="arrow-img mt-4">
                                          <img  src={arroww} />
                                          <Link to={"burdanbi"} className="link ml-2" style={{ color: '#f77e66' }}>
                                            KEŞFET{" "}
                                          </Link>
                                        </li>
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col lg="6" md="5">
                                  <img className="mr-4 dhimg-laptop" src={slider6} alt="" />
                                </Col>
                              </Row>
                            </Container>
                          ) : null}
                        </Row>
                      </div>
                    </div>
                    <div className="custom-owl-dots">
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            step1: true,
                            step2: false,
                            step3: false,
                            step4: false,
                            step5: false,
                            step6: false,
                            step7: false,
                          });

                        }}

                        className={
                          this.state.step1
                            ? "indicator-active"
                            : "indicator-inactive"
                        }

                      ></button>
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            step1: false,
                            step2: true,
                            step3: false,
                            step4: false,
                            step5: false,
                            step6: false,
                            step7: false,
                          });

                        }}
                        className={
                          this.state.step2
                            ? "indicator-active"
                            : "indicator-inactive"
                        }


                      ></button>
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            step1: false,
                            step2: false,
                            step3: true,
                            step4: false,
                            step5: false,
                            step6: false,
                            step7: false,
                          });
                        }}
                        className={
                          this.state.step3
                            ? "indicator-active"
                            : "indicator-inactive"
                        }

                      ></button>
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            step1: false,
                            step2: false,
                            step3: false,
                            step4: true,
                            step5: false,
                            step6: false,
                            step7: false,
                          });
                        }}
                        className={
                          this.state.step4
                            ? "indicator-active"
                            : "indicator-inactive"
                        }

                      ></button>
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            step1: false,
                            step2: false,
                            step3: false,
                            step4: false,
                            step5: true,
                            step6: false,
                            step7: false,
                          });
                        }}
                        className={
                          this.state.step5
                            ? "indicator-active"
                            : "indicator-inactive"
                        }

                      ></button>
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            step1: false,
                            step2: false,
                            step3: false,
                            step4: false,
                            step5: false,
                            step6: true,
                            step7: false,
                          });
                        }}
                        className={
                          this.state.step6
                            ? "indicator-active"
                            : "indicator-inactive"
                        }

                      ></button>
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            step1: false,
                            step2: false,
                            step3: false,
                            step4: false,
                            step5: false,
                            step6: false,
                            step7: true,
                          });
                        }}
                        className={
                          this.state.step7
                            ? "indicator-active"
                            : "indicator-inactive"
                        }

                      ></button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Row>
          </Container>
        </section>
        <section className="section">
          <Container>
            <div>
              <p className="sektorler-h3test text-center">Sektörler</p>
              <p className="sektorler-ptest">Hızla gelişen sektörlere ve buna bağlı kalarak işletmelere profesyonel çözümlerle yön vermekteyiz.</p>
            </div>
            <Row className="mt-5">
              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">Tekstil-Hazır Giyim</h4>
                  </Media>
                </div>
              </Col>

              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">İnşaat-Yapı</h4>
                  </Media>
                </div>
              </Col>

              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">Mobilya</h4>
                  </Media>
                </div>
              </Col>

              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">Toptan-Perakende</h4>
                  </Media>
                </div>
              </Col>

              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">Üretim</h4>
                  </Media>
                </div>
              </Col>

              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">Elektronik-Bilişim</h4>
                  </Media>
                </div>
              </Col>

              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">E-Ticaret</h4>
                  </Media>
                </div>
              </Col>

              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">Yiyecek-İçecek</h4>
                  </Media>
                </div>
              </Col>

              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">Sağlık-Spor</h4>
                  </Media>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">Hizmet</h4>
                  </Media>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">Serbest Meslek</h4>
                  </Media>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6} className="mt-3">
                <div className="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img className="mt-32mb-2 mr-3" src={arroww} />
                  <Media body>
                    <h4 className="title mb-0">Danışmanlık</h4>
                  </Media>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="orange p-5">
          <Container className="">
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="text-center">
                  <h3 className="mt-1 uyeol h3-uyeol" style={{ color: 'white' }}>Hemen Üye Olun 7 Gün Ücretsiz Deneyin</h3>
                  <p className="font-weight-light p-uyeol" style={{ color: 'white' }} >İhtiyacınıza en uygun ERP çözümlerini hemen ücretsiz deneyin.</p>
                  <div style={{ backgroundColor: 'white' }} className="mt-2 pt-2 uyeolbuton  btn rounded-pill">
                    <a className="baslik " href="tel:08503020304"> İletişime Geç</a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section" id="prices">
          <Container className="mb-4">
            <div className="col-12 text-center">
              <p className="fiyat-h3test ">SİZE EN UYGUN PLANI SEÇİN</p>
              <p className="ptest">Hemen şimdi ihtiyacınıza uygun planı seçin, en uygun fiyatlarla hızlı başarıyı yakalayın!</p>
            </div>
            <Row className="pricingrow">
              <PricingBox pricings={this.state.pricings} />
            </Row>
          </Container>
        </section>
        <Modal
          isOpen={this.state.loginModal}
          tabIndex="-1"
          centered
          contentClassName="rounded shadow-lg border-0 overflow-hidden"
          toggle={this.toggleLoginModal}
        >
          <ModalBody className="py-5">
            <Login config={Config} onSignUp={this.toggleSignUpModal} />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.signUpModal}
          tabIndex="-1"
          centered
          contentClassName="rounded shadow-lg border-0 overflow-hidden"
          toggle={this.toggleSignUpModal}
        >
          <ModalBody className="py-5">
            <SignUp onLogin={this.toggleLoginModal} />
          </ModalBody>
        </Modal>
      </React.Fragment>
    )

  }

}

export default withRouter(withTranslation()(HomePage));