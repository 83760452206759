import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Card, CardBody } from "reactstrap";
import { getUser } from "../../service/FetchUtil";


class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricings: [
        {
          id: 100,
          title: "Basic",
          price: "7.500",
          price2: "5.500",
          duration: "/Yıl",
          metin: "*Eylül sonuna kadar",
          duration2: "/Ay",
          commission: true,
          buttonText: "Ücretsiz Deneyin",
          testid: "btn_temel",
          btnLink: "/register?p=100",
          isActive: true,
          isSale: true,
          features: [
            { title: "Stok ve Depo Takibi" },
            { title: "Cari Takibi (Dövizli)" },
            { title: "Kasa/Banka Takibi" },
            { title: "Çek/Senet Takibi" },
            { title: "Personel Takibi ve Maaş Tahakkuk" },
            { title: "Nakit Akışı" },
            { title: "e-Fatura/e-Arşiv" },
            { title: "e-İrsaliye (Opsiyonel)" },
            { title: "500 e-kontör hediye", icon: "uil uil-gift" },
          ],
        },
        {
          id: 101,
          title: "Standard",
          price: "15.000,00",
          price2: "15.000",
          duration: "/Yıl",
          //metin: "* 7.210,00 ₺ (Net Satış Tutarı)",
          duration2: "/Ay",
          commission: true,
          buttonText: "Ücretsiz Deneyin",
          testid: "btn_eticaret",
          btnLink: "/register?p=101",
          features: [
            { title: "Şubeli Çalışma" },
            { title: "Stok ve Depo Takibi" },
            { title: "Cari Takibi (Dövizli)" },
            { title: "Kasa/Banka Takibi" },
            { title: "Çek/Senet/Taksit Takibi" },
            { title: "Nakit Akışı" },
            { title: "e-Fatura(e-Arşiv)/e-SMM" },
            { title: "e-İrsaliye" },
            { title: "500 e-kontör hediye", icon: "uil uil-gift" },
            { title: "Dinamik kart, miktar ve fiyat yönetimi" },
            { title: "Pazaryeri sipariş yönetimi" },
            { title: "trendyol,hepsiburada,n11 entegrasyonu", icon: "uil uil-shopping-cart" },
          ],
        },
        {
          id: 102,
          title: "Professional",
          price: "",
          price2: "35.000",
          duration: "/Yıl",
          duration2: "/Ay",
          buttonText: "Ücretsiz Deneyin",
          testid: "btn_burdanbi",
          btnLink: "/register?p=102",
          features: [
            { title: "Şubeli Çalışma" },
            { title: "Stok ve Depo Takibi" },
            { title: "Cari Takibi (Dövizli)" },
            { title: "Kasa/Banka Takibi" },
            { title: "Çek/Senet/Taksit Takibi" },
            { title: "Nakit Akışı" },
            { title: "e-Fatura(e-Arşiv)/e-SMM" },
            { title: "e-İrsaliye" },
            { title: "500 e-kontör hediye", icon: "uil uil-gift" },
            { title: "Dinamik kart, miktar ve fiyat yönetimi" },
            { title: "Pazaryeri sipariş yönetimi" },
            { title: "trendyol,hepsiburada,n11 entegrasyonu", icon: "uil uil-shopping-cart" },
          ],
        },
        {
          id: 103,
          title: "Enterprise",
          price: "60.000,00",
          price2: "60.000",
          duration: "/Lisans",
          duration2: "/Ay",
          buttonText: "Ücretsiz Deneyin",
          testid: "btn_burdanbi",
          btnLink: "/register?p=103",
          features: [
            { title: "Şubeli Çalışma" },
            { title: "Stok ve Depo Takibi" },
            { title: "Cari Takibi (Dövizli)" },
            { title: "Kasa/Banka Takibi" },
            { title: "Çek/Senet/Taksit Takibi" },
            { title: "Nakit Akışı" },
            { title: "e-Fatura(e-Arşiv)/e-SMM" },
            { title: "e-İrsaliye" },
            { title: "500 e-kontör hediye", icon: "uil uil-gift" },
            { title: "Dinamik kart, miktar ve fiyat yönetimi" },
            { title: "Pazaryeri sipariş yönetimi" },
            { title: "trendyol, hepsiburada, n11 entegrasyonu", icon: "uil uil-shopping-cart" },
          ],
        },
      ],
    };
  }
  render() {
    const user = getUser();
    return (
      <React.Fragment>
        {this.state.pricings.map((pricing, key) => (
          <Col
            name="pricing"
            xl="3"
            lg="3"
            md="3"
            sm="2"
            xs="12"
            key={key}
            className="mt-4 p-4"
          >
            <Card
              name="pricingbox"
              className={
                pricing.isActive
                  ? "pricing-rates business-rate shadow bg-white border-0"
                  : "pricing-rates business-rate shadow bg-white border-0"

              }
              style={{ borderRadius: '15px' }}

            >
              {pricing.isActive && (
                <div className="ribbon ribbon-right ribbon-warning overflow-hidden">
                  <span className="text-center d-block shadow small h6">
                    Kampanya
                  </span>
                </div>
              )}
              <CardBody >
                <h4
                  className={
                    pricing.isActive
                      ? "font-weight-bold text-primary mb-4"
                      : "font-weight-bold text-primary mb-4"
                  }
                >
                  {pricing.title}
                </h4>
                <div  className="d-flex mb-2" name="pricingsection" >
                  {pricing.isSale ? (
                    <del className="text-danger ms-2" style={{fontSize:20}}>
                      {pricing.price} <span>₺</span>
                    </del>
                  ) :null}
                </div>
                <div className="mb-3" name="pricingsection" style={{position:'relative',top:'-1em'}}>
                  <p className="ml-3 mb-0 text-danger font-weight-bold" style={{fontSize:12}}>{pricing.metin}</p>
                </div>
                <div className="d-flex mb-3" name="pricingsection"style={{position:'relative',top:'-1em'}}>
                  <span className="price h2 mb-0">{pricing.price2}</span>
                  <span className="h4 mb-0 mt-2">₺</span>
                  <span style={{ fontSize: "0.8em" }} className="align-self-end mb-1">
                    +KDV{pricing.duration}
                  </span>
                  {/*  <p style={{ fontSize: '11px' }} className="text-muted ml-8 mt-8">+KDV</p>*/}
                </div>
                <ul className="list-unstyled mb-0 pl-0">
                  {pricing.features.map((feature, key) => (
                    <li key={key} className="pricing-feature text-muted mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className={(feature.icon || "uil uil-check-circle") + " align-middle"}></i>
                      </span>
                      {feature.title}
                    </li>
                  ))}
                </ul>
                {user && user.data ?
                  <Link to="/mytenants" testid={pricing.testid} style={{ fontSize: "0.8em" }} className="btn btn-secondary  mt-4" >
                    İşletmelerinizi inceleyin
                  </Link>

                  : (!user ?
                    <a href="/login" testid={pricing.testid} className="btn btn-info  mt-4" >
                      Giriş Yapın
                    </a>
                    :
                    <Link testid={pricing.testid} to={pricing.btnLink} className="btn btn-secondary  mt-4" >
                      {pricing.buttonText}
                    </Link>)}
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default Pricing;
