import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));

const Footer = React.lazy(() => import("./Footer"));

const CustomDot = () => {
  return (
    <i>
      <FeatherIcon icon="arrow-up" className="icons" />
    </i>
  );
};

class Layout extends Component {
  Loader = () => {
    window.scrollTo(0, 0);
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <Suspense fallback={this.Loader()}>
      <Topbar config={this.props.config}/>

      {this.props.children}

      <Footer version={this.props.version}/>
    </Suspense>;
  }
}

export default withRouter(Layout);