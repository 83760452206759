import currency from 'currency.js';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3500
});

export const CurrencyFormatOptions = {
    "0": {
        get: (precision) => { return { separator: '.', decimal: ',', symbol: '₺', precision: precision } }
    },
    "1": {
        get: (precision) => { return { separator: ',', decimal: '.', symbol: '$', precision: precision } }
    },
    "2": {
        get: (precision) => { return { separator: ',', decimal: '.', symbol: '€', precision: precision } }
    },
    "3": {
        get: (precision) => { return { separator: ',', decimal: '.', symbol: '£', precision: precision } },
    }
}
const CurrencyFormats = {
    "0": {
        label: "TL",
        get: (value, percision) => currency(value, CurrencyFormatOptions["0"].get(percision ?? 2))
    },
    "1": {
        label: "USD",
        get: (value, percision) => currency(value, CurrencyFormatOptions["1"].get(percision ?? 2))
    },
    "2": {
        label: "Euro",
        get: (value, percision) => currency(value, CurrencyFormatOptions["2"].get(percision ?? 2))
    },
    "3": {
        label: "GBP",
        get: (value, percision) => currency(value, CurrencyFormatOptions["3"].get(percision ?? 2))
    }
}

export const formatCurrency = (val, unitId, percision) => {
    return CurrencyFormats[unitId || 0].get(val, percision).format();
}
export const formatCurrencyTL = (val, percision) => {
    return formatCurrency(val, 0, percision);
}

export const showConfirm = (msg, onok, oncancel) => {
    Swal({
        title: 'Onay Gerekli',
        html: msg,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Tamam',
        cancelButtonColor: '#3085d6',
        cancelButtonText: 'İptal'
    }).then((result) => {
        if (result.value) {
            if (onok)
                onok();
        } else if (oncancel)
            oncancel();
    });
}
export const showMessage = (msg, onok) => {
    Swal({
        title: 'Dikkat',
        html: msg,
        type: 'success',
        showCancelButton: false,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Tamam',
    }).then((result) => {
        if (result.value) {
            if (onok)
                onok();
        } else if (oncancel)
            oncancel();
    });
}
export const showYesNo = (msg, onok, oncancel) => {
    Swal({
        title: 'Onay Gerekli',
        html: msg,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Evet',
        cancelButtonColor: '#3085d6',
        cancelButtonText: 'Hayır'
    }).then((result) => {
        if (result.value) {
            if (onok)
                onok();
        } else if (oncancel)
            oncancel();
    });
}
export const showError = (error) => {
    Swal.fire('Hay aksi...', error, 'error');
}
export const showWarning = (warning) => {
    Swal.fire('Uyarı', warning, 'warning');
}
export const showSuccess = (warning) => {
    Swal.fire('Başarılı', warning, 'success');
}
export const notifySuccess = (message) => {
    Toast.fire({
        icon: 'success',
        title: message
    })
}
export const notifyWarning = (message) => {
    Toast.fire({
        icon: 'warning',
        title: message
    })
}
export const notifyError = (message) => {
    Toast.fire({
        icon: 'error',
        title: message
    })
}

export const checkPasswordStrength = (password) => {
    // Initialize variables
    var strength = 0;
    var tips = "";

    // Check password length
    if (password.length < 6) {
        tips += "\nEn az 6 karakter uzunluğunda olmalıdır.";
    } else {
        strength += 1;
    }

    // Check for mixed case
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
        strength += 1;
    } else {
        tips += "\nBüyük ve küçük harfler içermelidir.";
    }

    // Check for numbers
    if (password.match(/\d/)) {
        strength += 1;
    } else {
        tips += "\nEn az bir rakam içermelidir.";
    }

    // Check for special characters
    if (password.match(/[^a-zA-Z\d]/)) {
        strength += 1;
    } else {
        tips += "\nEn az bir özel karakter içermelidir.";
    }

    return { strength, tips: tips.trimStart().replace("\n","<br/>") };
    /*if (strength < 2) {
        return "Easy to guess. " + tips;
    } else if (strength === 2) {
        return "Medium difficulty. " + tips;
    } else if (strength === 3) {
        return "Difficult. " + tips;
    } else {
        return "Extremely difficult. " + tips;
    }*/
}